import React, {Fragment} from "reactn";
import { IMaskInput } from "react-imask";

const inputOTP = ({id}) => {
    return (        
    <Fragment>
    <div className="divinputStyle">
    <label className="labelinputStyle">Introduce el código aquí</label>
    <IMaskInput
										type="search"
										className="inputStyle"
										mask={'000000'}
										radix="."
										value=""
										id={id}
										unmask={true}
										required
										autoComplete="off"
									/>
    {/* <input id={id} className="inputStyle" type="text" maxLength={6} /> */}
  </div>

  <div className="divProgress">
  <div className="divInputStyleProgress">0</div>
  <div className="divInputStyleProgress">1</div>
  <div className="divInputStyleProgress">2</div>
  <div className="divInputStyleProgress">3</div>
  <div className="divInputStyleProgress">4</div>
  <div className="divInputStyleProgress">5</div>  
  </div> 
  </Fragment>);
}
 
export default inputOTP;