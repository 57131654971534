import React,{setGlobal,addCallback} from 'reactn'
import ReactDOM from 'react-dom'
import App from './App'
import 'uikit/dist/css/uikit.min.css'
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons'
import './assets/sass/style.scss'
import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import { BrowserRouter } from 'react-router-dom';


UIkit.use(Icons);

addCallback(global => {
    // console.log(`[ global state changed ] : `, global);
});

setGlobal({
    "session": null,
	"schema": {},
	"definition": {}
})

addCallback(global => {
    // console.log(`The new value is: `);
    // console.log(global);
});

ReactDOM.render(<BrowserRouter> 
<App />
</BrowserRouter>, document.getElementById('root'));


