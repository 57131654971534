import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import TooltipMUI from "@material-ui/core/Tooltip";
import axios from "axios";
import { REACT_APP_API_URL } from "../../App";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Table from "./Table";
import { Pie, Bar, Doughnut, Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import * as Chartjs from "chart.js";

const controllers = Object.values(Chartjs).filter(
  (chart) => chart.id !== undefined
);

Chart.register(...controllers);

const initialUser = {
  firstName: "",
  lastName: "",
  age: 0,
  visits: 0,
  status: "single",
  progress: 0,
  subRows: undefined,
};

const AddUserDialog = (props) => {
  const [user, setUser] = useState(initialUser);
  const { addUserHandler } = props;
  const [open, setOpen] = useState(false);
  const [searhTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [Forms, setForms] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [variables, setVar] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [labelsToCharts, setLabels] = useState([]);
  const [answersCountToCharts, setAnswersCount] = useState([]);
  const [type, setType] = useState('bar');


  const data = {
    labels: labelsToCharts,
    datasets: [
      {
        label: "# of Votes",
        type: type, 
        data: answersCountToCharts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        hoverOffset: 30,
        borderAlign: 'center',
        hoverBorderJoinStyle: 'round',
        rotation: 30,
        offset: 10,
        spacing: 10,
        weight: 10,
        animateScale: true,
        // circumference: 10,
        clip: 10
      },
    ],
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
  };

  const [switchState, setSwitchState] = useState({
    addMultiple: false,
  });

  const handleSwitchChange = (name) => (event) => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetSwitch();
  };

  const handleAdd = (event) => {
    addUserHandler(user);
    setUser(initialUser);
    switchState.addMultiple ? setOpen(true) : setOpen(false);
  };

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setUser({ ...user, [name]: value });
    };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // axios.post(`${REACT_APP_API_URL}`)
    console.log([data, startDate, endDate]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setIsLoaded(false);
        const response = await axios.get(
          `${REACT_APP_API_URL}/core/v1/keyslabels`,
          { headers }
        );
        setForms(response.data);
        // setIsLoaded(true);
      } catch (error) {
        console.log("error", error);
        // setIsLoaded(true);
        setError(error);
      }
    };

    fetchData();
  }, []);


  const handleStr = (str) => {
    str = str.toLowerCase();
    str = str.replace(/_/g, "");
    return str.replace("surveyform", "");
  };

  const handleSearchToDB = (value) => {
    if (variables.includes(value)) {
      let updateVariables = variables.filter((element) => element != value);
      setVar(updateVariables);
    }
    if (variables.length === 15) {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Solo puedes tomar 15 criterios para realizar tu búsqueda.",
      });
      
    } else if (!variables.includes(value)) {
      setVar(variables.concat(value));
      // (answers) ? handlePostRequest() : console.log('first')
    }
  };

  const handlePostRequest = async () => {
    setIsLoaded(false);
    function sum(obj) {
      var sum = 0;
      for (var el in obj) {
        if (obj[el].answer != null) {
          sum += parseFloat(obj[el].answer_count);
        }
        if (obj[el].answer === null) {
          delete obj[el];
        }
      }
      return sum;
    }

    const response = await axios.post(
      `${REACT_APP_API_URL}/core/v1/getFormAnswersStatsByDate`,
      {
        startDate: new Date(startDate).toISOString().split("T")[0],
        endDate: new Date(endDate).toISOString().split("T")[0],
        keys: { ...variables },
      },
      { headers }
    );
    setIsLoaded(true);

    // let labelsIntoArrays = [];
    // var grouped = _.mapValues(_.groupBy(response.data.rows, "key"), (list) =>
    //   list.map((val, i) => {
    //     let valuesFromObj = Object.values(val);
    //     if(valuesFromObj.length > 1) valuesFromObj = _.dropRight(valuesFromObj);
    //     if (valuesFromObj[4] === undefined && valuesFromObj[4] === null) {
    //       valuesFromObj = _.compact(valuesFromObj);
    //     } else {
    //       labelsIntoArrays.push(valuesFromObj[4]);
    //     }
    //   })
    // );
    // setLabels(_.compact(labelsIntoArrays));

    // let answersCountIntoArrays = [];
    // var grouped = _.mapValues(_.groupBy(response.data.rows, "key"), (list) =>
    //   list.map((val, i) => {
    //     let valuesFromObj = Object.values(val);
    //     // valuesFromObj = _.dropRight(valuesFromObj);
    //     if (valuesFromObj[4] === null && valuesFromObj[4] === undefined) {
    //       valuesFromObj = _.compact(valuesFromObj);
    //     } else {
    //       answersCountIntoArrays.push(valuesFromObj[5]);
    //     }
    //   })
    // );

    // setAnswersCount((answersCountIntoArrays.length > 1) ? _.dropRight(answersCountIntoArrays) : answersCountIntoArrays);

    // let groupByLabel = _.chain(response.data.rows)
    //   .groupBy("label")
    //   .map((value, label) => ({
    //     label: label,
    //     key: value[0].key,
    //     values: value,
    //     totalAnswer: sum(value),
    //   }))
    //   .value();


    setAnswers(response.data);
  };

  // const handleDelete = (key) => {

  //   if (variables.includes(key)) {

  //     let updateVariables = variables.filter((element) => element != key);
  //     // setVar([...updateVariables]);
  //     // console.log('updateVariables', updateVariables);
  //     // console.log('variables', variables)

  //     handlePostRequest([...updateVariables]);
  //   }
  // };

  return (
    <div>
      <TooltipMUI title="Crear nuevo reporte">
        <button
          onClick={handleClickOpen}
          className="uk-button uk-button-primary btn-add-report"
        >
          Crear nuevo reporte 
        </button>
      </TooltipMUI>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        // className="MuiDialog-paperWidthSm"
      >
        <div data-uk-overflow-auto className="uk-modal-body">
          <div>
            <h3 style={{ color: "#132c54" }} className="uk-text-center">
              COMBÍNALO HASTA CON 15 VARIABLES.
            </h3>
          </div>
          <div data-uk-grid className="uk-grid-divider">
            <div className="uk-width-1-3">
              <div className="uk-padding uk-padding-remove-bottom">
                <div className="uk-search uk-search-default uk-margin-bottom">
                  <span
                    className="uk-search-icon-flip"
                    data-uk-search-icon
                  ></span>
                  <input
                    className="uk-search-input"
                    autoFocus={true}
                    type="search"
                    placeholder={`${Forms.length} formularios...  `}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
                <ul className="uk-nav" data-uk-accordion data-uk-nav>
                  <>
                    {Forms == 0 ? (
                      <div className="uk-margin-top">
                        <p>No hay formularios para mostrar.</p>
                      </div>
                    ) : (
                      Forms.filter((val) => {
                        val = val.survey_title.replace(/_/g, "");
                        val = val.toLowerCase();
                        val = val.replace("surveyform", "");
                        if (searhTerm == "") {
                          return val;
                        } else if (val.includes(searhTerm.toLowerCase())) {
                          return val;
                        }
                      }).map((keyforms, index) => (
                        <>
                          <li key={index}>
                            <a
                              style={{ color: "black" }}
                              className="uk-text-capitalize li-a-forms uk-accordion-title"
                              href="#"
                            >
                              {handleStr(keyforms?.survey_title)}
                            </a>
                            <div className="uk-accordion-content">
                              <ul className="uk-list uk-list-divider">
                                {keyforms?.label.map((label, index) => (
                                  <>
                                    <li>
                                      <div className="uk-inline uk-width-1-1">
                                        <span
                                          // title="Agregar variable"
                                          className="uk-display-block uk-margin-large-right"
                                        >
                                          {label}
                                          <a
                                            style={{
                                              backgroundColor: `${
                                                variables.includes(
                                                  keyforms.key[
                                                    keyforms.survey_title
                                                  ][index]
                                                )
                                                  ? "gray"
                                                  : "#1e87f0"
                                              }`,
                                            }}
                                            title={`Agregar ${label}`}
                                            onClick={() =>
                                              handleSearchToDB(
                                                keyforms.key[
                                                  keyforms.survey_title
                                                ][index]
                                              )
                                            }
                                            className="uk-form-icon uk-form-icon-flip uk-light uk-border-rounded uk-position-z-index icon-a-add-report"
                                            uk-icon={`${
                                              variables.includes(
                                                keyforms.key[
                                                  keyforms.survey_title
                                                ][index]
                                              )
                                                ? "icon: check"
                                                : "icon: plus"
                                            }`}
                                          ></a>
                                        </span>
                                      </div>
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>
                            <hr style={{ margin: "6px 0px 6px 0px" }} />
                            {/* <ul className="uk-nav-sub"></ul> */}
                          </li>
                        </>
                      ))
                    )}
                  </>
                </ul>
              </div>
            </div>
            <div className="uk-width-2-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  data-uk-grid
                  className="uk-grid uk-margin-bottom uk-grid-small"
                >
                  <div className="uk-width-1-3">
                    <label>Desde&nbsp;</label>
                    <div className="uk-inline">
                      <span
                        style={{ backgroundColor: "#1e87f0" }}
                        className="uk-form-icon uk-form-icon-flip uk-light uk-position-z-index"
                        uk-icon="icon: calendar"
                      ></span>
                      <DatePicker
                        // autoFocus={true}
                        dateFormat="yyyy/MM/dd"
                        peekNextMonth
                        maxDate={endDate}
                        endDate={endDate}
                        startDate={startDate}
                        // selectsStart
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={startDate}
                        {...register("startDate")}
                        required
                        onChange={(date) => {
                          // handleSubmit(date)
                          setStartDate(date);
                        }}
                      />
                    </div>
                  </div>
                  <div className="uk-width-1-3">
                    <label>Hasta&nbsp;</label>
                    <div className="uk-inline">
                      <span
                        style={{ backgroundColor: "#1e87f0" }}
                        className="uk-form-icon uk-form-icon-flip uk-light uk-position-z-index"
                        uk-icon="icon: calendar"
                      ></span>
                      <DatePicker
                        {...register("endDate")}
                        dateFormat="yyyy/MM/dd"
                        selected={endDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        required
                        // selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dropdownMode="select"
                        onChange={(date) => {
                          // handleSubmit(date)
                          setEndDate(date);
                        }}
                      />
                    </div>
                  </div>

                  <div className="uk-width-1-3">
                    <label className="uk-display-block">&nbsp;</label>
                    <div className="uk-search uk-search-default uk-margin-bottom">
                      <DialogActions>
                        <TooltipMUI title="Buscar reporte">
                          <Button onClick={handlePostRequest} color="primary">
                            Buscar
                          </Button>
                        </TooltipMUI>
                      </DialogActions>
                    </div>
                  </div>
                </div>

                {/* aqui van las respuestas de los formurlaios */}
                <div>
                  <ul className="uk-list">
                    {!isLoaded ? (
                      <div className="uk-margin-top uk-text-center">
                        <Box sx={{ width: 810 }}>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </Box>
                      </div>
                    ) : answers == 0 && isLoaded ? (
                      <div className="uk-margin-top">
                        <p>No hay data para mostrar.</p>
                      </div>
                    ) : (
                      <>
                        <div style={{ display: "block" }}>
                          <Table answers={answers} />
                        </div>

                        <div className="uk-container uk-width-1-1">
                          <div
                            style={{
                              border: "1px solid #ccc",
                              padding: "10px",
                            }}
                            className="uk-flex uk-border-rounded uk-margin-bottom uk-flex-column"
                          >
                            <div className="uk-margin-auto-left">
                              <nav data-uk-navbar="mode: click">
                                <div className="uk-navbar-left">
                                  <ul class="uk-navbar-nav">
                                    <li>
                                      <span
                                        title="Ajustes"
                                        style={{ cursor: "pointer" }}
                                        uk-icon="icon: cog"
                                      >
                                        <a href="#"></a>
                                      </span>

                                      <div class="uk-navbar-dropdown">
                                        <ul
                                          data-uk-nav
                                          class="uk-nav uk-navbar-dropdown-nav uk-nav-parent-icon"
                                        >
                                          <li class="uk-active">
                                            <a href="#">Menú de opciones</a>
                                          </li>
                                          <li className="uk-parent">
                                            <a href="#">
                                              <span
                                                class="uk-margin-small-right"
                                                uk-icon="icon: thumbnails"
                                              ></span>
                                              Elegir gráficos
                                            </a>
                                            <ul class="uk-nav-sub">
                                              <li>
                                                <a onClick={ () => setType('pie')} href="#">Pie</a>
                                              </li>
                                              <li>
                                                <a onClick={ () => setType('doughnut')} href="#">Doughnut</a>
                                              </li>
                                              <li>
                                                <a onClick={ () => setType('bar')} href="#">Bar</a>
                                              </li>
                                              <li>
                                                <a onClick={ () => setType('line')} href="#">Line</a>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <a href="#">
                                              <span
                                                class="uk-margin-small-right"
                                                uk-icon="icon: file-edit"
                                              ></span>
                                              Editar
                                            </a>
                                          </li>
                                          <li>
                                            <a href="#">
                                              <span
                                                class="uk-margin-small-right"
                                                uk-icon="icon: download"
                                              ></span>
                                              Exportar a Excel
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              // onClick={() =>
                                              //   handleDelete(answ.key)
                                              // }
                                              href="#"
                                            >
                                              <span
                                                class="uk-margin-small-right"
                                                uk-icon="icon: trash"
                                              ></span>
                                              Eliminar
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </nav>
                            </div>

                                {/* <div className="uk-text-center">
                                  <p className="uk-margin-remove">
                                    <strong>label</strong>
                                  </p>
                                </div> */}

                              <div
                                  style={{ width: '70%' }}
                                  className="uk-overflow-auto uk-container"
                                >
                                  {/* <Pie data={data} /> */}
                                  <Bar data={data} />
                                  {/* <Doughnut data={data} />
                                  <Line data={data} /> */}
                                </div>

                                <hr />
{/*                                 
                                <div className="uk-flex uk-flex-between uk-margin-top">
                                  <span>
                                    &nbsp; Total de respuestas:{" "}
                                    <strong>'klk'</strong>
                                  </span>
                                  <span>
                                    &nbsp; Total de encuestados:{" "}
                                    <strong>23,424</strong>
                                  </span>
                                </div> */}
                          </div>

                        </div>
                      </>
                    )}
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="uk-modal-footer">
          <DialogActions>
            <TooltipMUI title="Cancelar">
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
            </TooltipMUI>
            <TooltipMUI title="Agregar reporte">
              <Button onClick={handleAdd} color="primary">
                Agregar
              </Button>
            </TooltipMUI>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

AddUserDialog.propTypes = {
  addUserHandler: PropTypes.func.isRequired,
};

export default AddUserDialog;
