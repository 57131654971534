import React, { useState, useEffect } from "reactn";
import axios from "axios";
import UIkit from "uikit";
import { FormBuilder } from "react-formio";
import history from "../../browserHistory";
import { REACT_APP_API_URL } from "../../App";
import swal from "sweetalert2";
import moment from "moment";
require("dotenv").config();
const validator = require("validator");

let headersForm = {
  headers: {
    'Content-Type': "application/json",
    'Authorization': `Bearer ${localStorage.getItem("tokenAdmin")}`,
  },
};
// console.log('window.origin: ', window.origin)
const UpdateForm = async (id, properties) => {
  return await axios.put(
    `${REACT_APP_API_URL}/core/v1/forms/definitions/${id}`,
    properties,
    headersForm
  );
};
let definitionToUpdate = {};
const FormHubUpdate = (props) => {
  const getMetaProfile = async (key, token) => {
    try {
      let meta = await axios.get(
        `${REACT_APP_API_URL}/core/v1/forms/definitions/${key}/`,
        headersForm
      );
      await setReward(meta.data.properties.reward);
      await setSurveyTitle(meta.data.survey_title);
      await setStatusSurveyFromDB(meta.data.status);
      meta = meta.data.properties.definition;
      await setDefinition(meta);
      await setDisplay(meta.display);
    } catch (err) {
      await swal.fire({
        icon: "error",
        title: "Sesión caducada.",
        text: "Tu sesión ha caducado, inicia sesión nuevamente!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: `
						rgb(91, 91, 91)
						center left
						no-repeat
  					`,
      });
      await history.push("/");
      await history.go();
    }
  };

  const [definition, setDefinition] = useState({ display: "wizard" });
  const [display, setDisplay] = useState("wizard");
  const [reward, setReward] = useState({ date: "", reward: "", duration: "" });
  const [surveyTitle, setSurveyTitle] = useState("");
  const [statusSurvey, setStatusSurvey] = useState("");
  const [statusSurveyFromDB, setStatusSurveyFromDB] = useState("");
  let key = window.location.pathname.replace("/admin/formhub/update/", "");

  useEffect(() => {
    let jwt = localStorage.getItem("tokenAdmin");
    getMetaProfile(key, jwt);
    document.querySelector("#input-key").value = key;
    document.querySelector("#input-key").className = "uk-disabled";
  }, []);

  function calculateFormMeta(schema) {
    const key = document.querySelector("#input-key");
    const survey_title = document.querySelector("#input-name");
    const valueStatus = document.querySelector("#status-survey");
    const formProfile = Object.assign(
      {
        key: key.value,
        survey_title: surveyTitle,
        updated_at: moment().format(),
      },
      {
        properties: {
          scope: "anonymous",
          definition: schema,
          reward,
        },
      },
      {
        status: !statusSurvey ? statusSurveyFromDB : statusSurvey,
      }
    );
    return formProfile;
  }

  let handleOnDisplay = (e) => {
    setDisplay(e.target.value);
    setDefinition({ display: e.target.value, component: undefined });
  };

  let handleOnSubmit = async (e) => {
    // console.log("status: ", statusSurvey);
    // console.log("statusSurveyFromDB", statusSurveyFromDB);
    let key = document.querySelector("#input-key");
    let survey_title = document.querySelector("#input-name");
    let valueStatus = document.querySelector("#status-survey");

    if (validator.isEmpty(valueStatus.value)) {
      document.querySelector("#status-survey").style.borderColor = "red";
      UIkit.notification({
        message: "Selecciona un estatus!",
        status: "danger",
        pos: "top-right",
      });
      return false;
    }
    if (validator.isEmpty(key.value)) {
      document.querySelector("#input-key").style.borderColor = "red";
      UIkit.notification({
        message: "Campo de llave vacio!",
        status: "danger",
        pos: "top-right",
      });
      return false;
    } else if (!key.value.indexOf(" ") == 1) {
      UIkit.notification({
        message: "Campo de llave vacio!",
        status: "danger",
        pos: "top-right",
      });
      return false;
    } else if (!survey_title.value.indexOf(" ") == 1) {
      UIkit.notification({
        message: "Campo de nombre vacio!",
        status: "danger",
        pos: "top-right",
      });
      return false;
    } else if (!survey_title.value.indexOf(" ") == 1) {
      UIkit.notification({
        message: "Campo de nombre vacio!",
        status: "danger",
        pos: "top-right",
      });
      return false;
    } else {
      key = key.value.split(" ").join("_");
      let formProfile = Object.assign(
        {
          key: key,
        },
        {
          properties: {
            scope: "anonymous",
            definition: definitionToUpdate,
            reward,
          },
        },
        {
          status: !statusSurvey ? statusSurveyFromDB : statusSurvey,
        }
      );
      formProfile.survey_title = surveyTitle;
      formProfile.updated_at = moment().format();
      document.querySelector("#input-key").style.borderColor = "#e5e5e5";
      document.querySelector("#input-key").value = "";
      document.querySelector("#input-name").style.borderColor = "#e5e5e5";
      document.querySelector("#input-name").value = "";
      await UpdateForm(key, formProfile);
      await UIkit.notification({
        message: "Updated!",
        status: "success",
        pos: "top-right",
      });
      await history.push("/admin");
      await history.go();
    }
  };

  return (
    <div className="">
      <div className="uk-container uk-width-1-1 uk-padding-remove">
        <div className="uk-flex uk-flex-around uk-margin-remove rule-menu uk-width-1-1 uk-padding-small">
          <div>
            <select
              className="uk-select"
              name="displayForm"
              id="displayForm"
              value={display}
              onChange={(e) => {
                handleOnDisplay(e);
              }}
            >
              <option value="form">Form</option>
              <option value="wizard">Wizard</option>
              {/* <option value="pdf">PDF</option> */}
            </select>
          </div>
          <div>
            <label className="uk-label uk-align-right labelKey">Nombre</label>
          </div>

          <div>
            <input
              style={{
                textAlign: "center",
              }}
              value={surveyTitle}
              type="text"
              id="input-name"
              className="uk-input uk-align-center"
              placeholder="Nombra tu encuesta"
              onChange={(e) => {
                setSurveyTitle(e.target.value);
              }}
            />
          </div>
          <div>
            <label className="uk-label uk-align-right labelKey">Key</label>
          </div>
          <div>
            <input
              type="text"
              id="input-key"
              className="uk-input uk-align-center"
            />
          </div>

          <div>
            <select
              defaultValue={statusSurveyFromDB}
              id="status-survey"
              onChange={(e) => setStatusSurvey(e.target.value)}
              className="uk-select"
            >
              <option value="200000">Habilitado</option>
              <option value="400000">Deshabilitado</option>
            </select>
          </div>
          <div>
            <button
              type="submit"
              className="uk-button uk-button-primary"
              onClick={(e) => {
                handleOnSubmit();
              }}
            >
              Update
            </button>
          </div>
        </div>
        <div className="uk-margin-large-top uk-margin-bottom">
          <FormBuilder
            form={definition}
            onChange={(change) => {
              console.log("form.io schema", calculateFormMeta(change));
              definitionToUpdate = change;
            }}
          />
        </div>
        <div className="uk-margin-large-top uk-margin-bottom">
          <textarea
            value={JSON.stringify(reward)}
            name="UNIQUE_ID_OF_DIV"
            id=""
            cols="30"
            rows="10"
            onChange={(change) => {
              // console.log("Cambio", change.target.value)
              setReward(JSON.parse(change.target.value));
            }}
          ></textarea>
        </div>
        <div className="uk-margin-large-top uk-margin-bottom uk-width-1-1 uk-padding-large">
          <textarea
            value={JSON.stringify(definition)}
            name="formDefinitionJSON"
            id=""
            cols="50"
            rows="50"
            onChange={(change) => {
              // console.log("Cambio", change.target.value)
              setDefinition(JSON.parse(change.target.value));
            }}
			className="uk-width-1-1"
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default FormHubUpdate;
