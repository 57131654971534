import React, {useEffect, useState, useGlobal} from 'reactn';
import avatar from '../assets/images/Avatar.webp';
import { createBrowserHistory } from 'history';
import swal from "sweetalert2";
import axios from "axios";
import { REACT_APP_API_URL } from "../App";
import jwt from "jsonwebtoken";
const history = createBrowserHistory();

let axiosConfig = {
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`
	}
};

const Header = () => {
	// const [isloading, setIsLoading] = useState(true);
    // const [session, setSession] = useGlobal('session');
	const [isExpired, setIsExpired] = useState(false);

	
	useEffect(() => {
		const token = localStorage.getItem("tokenAdmin");
		let decodedToken = jwt.decode(token, { complete: true });
		let dateToken = new Date(decodedToken.payload.exp * 1000);
		let dateNow = new Date().getTime();
		(dateToken < dateNow) ? setIsExpired(true) : setIsExpired(false)
		const fetchProfiles = async () => {
			try {
				// setIsLoading(true);
				let res = await axios.get(
				  `${REACT_APP_API_URL}/core/v1/users/profiles`,
				  axiosConfig
				);
				// await setSession(res.data);
				 localStorage.setItem('mobilePhoneNumber', res.data.mobile_phone_number)
				 localStorage.setItem('firstName', res.data.first_name)
				 localStorage.setItem('lastName', res.data.last_name)
				 localStorage.setItem('idUser', res.data.id)
				// await setIsLoading(false);
			  } catch (err) {
				if (isExpired) {
					swal
					.fire({
					  icon: "error",
					  title: "Sesión caducada.",
					  text: "Tu sesión ha caducado, inicia sesión nuevamente!",
					  allowOutsideClick: false,
					  allowEscapeKey: false,
					  backdrop: `
					  rgb(91, 91, 91)
					  center left
					  no-repeat
					  `,
					})
					.then((notif) => {
					  if (notif.value) {
						localStorage.removeItem("email");
						localStorage.removeItem("tokenAdmin");
						history.push("/");
						history.go();
					  }
					});
					   
					  } else {
						await swal.fire({
						  icon: "error",
						  title: "Error al intentar realizar una consulta.",
						  text: "Hay algún problema interno, por favor contacta al administrador.",
						  allowOutsideClick: false,
						  allowEscapeKey: false,
						  backdrop: `
							rgb(91, 91, 91)
							center left
							no-repeat
							`,
						});
					 
					  }
			  }
		}
		fetchProfiles()
	}, [])
	// let randomnumber = Math.floor(Math.random() * (1 - 99 + 1)) + 99;

	const closeSession = (e) => {
		e.preventDefault();

		swal
			.fire({
				title: '¿Estás seguro que quieres salir?',
				icon: 'warning',
				confirmButtonText: 'Sí, deseo salir.',
				confirmButtonColor: '#008f39',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				cancelButtonColor: '#d33',
				showCloseButton: true
			})
			.then((resp) => {
				if (resp.value) {
					localStorage.removeItem('tokenAdmin');
					localStorage.removeItem('email');
					history.push('/');
					history.go();
				}
			});
	};

	return (
		<header className="uk-flex">
			{/* PROFILE IMAGE */}
			<div className="uk-width-1-5@s uk-padding-small profile-info">
				<a href="/admin" className="uk-link-reset">
					<img className="uk-border-pill" src={avatar} width="50" alt="" />
					<span style={{ color: 'white' }} className="uk-text-small uk-margin-left uk-text-capitalize">
						Administrador
					</span>
					{/* <span data-uk-icon="chevron-down" className="uk-margin-left" /> */}
				</a>
			</div>

			{/* TOP NAVIGATION */}
			<div className="uk-padding-small uk-padding-remove-vertical uk-width-1 nav-top">
				<nav uk-navbar="mode: click">
					<div className="uk-navbar-right">
						<ul className="uk-navbar-nav uk-flex uk-flex-middle">
							<li>
								<span style={{color: 'white'}} className="uk-text-capitalize">
								{`¡Hola ${localStorage.getItem('firstName')}!`}	
								</span>
							</li>
							<li>
								<a>
									<span uk-icon="icon: cog" />
								</a>
								<div className="uk-navbar-dropdown">
									<ul className="uk-nav uk-navbar-dropdown-nav">
										<li>
											<a href="/admin/account">
												<span className="uk-margin-small-right" uk-icon="icon: user" />My
												Account
											</a>
										</li>
										<li>
											<a onClick={(e) => closeSession(e)}>
												<span className="uk-margin-small-right" uk-icon="icon: sign-out" />Sign
												out
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</header>
	);
};

export default Header;
