import React, { useState } from 'reactn';
import AdminLayout from '../Layout/AdminLayout';
import { IMaskInput } from 'react-imask';
import axios from 'axios';
import { REACT_APP_API_URL } from '../App';
import swal from 'sweetalert2';


let axiosConfig = {
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${localStorage.getItem('tokenAdmin')}`
	}
};

const UserAdminProfile = () => {
	let handleOnChange = (e) => {
		let first_name = document.getElementById('firstName').value;
		let last_name = document.getElementById('lastName').value;
		let mobile_Number = document.getElementById('mobilePhoneNumber').value;

		localStorage.setItem('firstName', first_name);
		localStorage.setItem('lastName', last_name);
		localStorage.setItem('mobilePhoneNumber', mobile_Number);
	};

	let handleUpdateInfo = async (e) => {
		e.preventDefault();
		let userInfo = {
			first_name: localStorage.getItem('firstName'),
			id: localStorage.getItem('idUser'),
			last_name: localStorage.getItem('lastName'),
			mobile_phone_number: localStorage.getItem('mobilePhoneNumber').replace(/[^a-zA-Z0-9 ]/g, '')
		};
		swal
			.fire({
				title: '¿Estás seguro?',
				text: 'Estás actualizando información de tu perfil.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#008f39',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Sí, actualiza!'
			})
			.then((resp) => {
				if (resp.value) {
					axios
						.put(`${REACT_APP_API_URL}/core/v1/users/profiles`, userInfo, axiosConfig)
						.then((res) => {
							swal.fire({
								title: '¡Actualizado!',
								text: "Información actualizada",
								icon: 'success',
								allowOutsideClick: false,
								allowEscapeKey: false
								
						}).then((res)=> {
								if (res.isConfirmed) {
									window.location.reload();
								}	
							})
					});
				} else {
					swal.fire('Cancelado!', 'Tus informaciones se mantienen intactas', 'info');
				}
			});
	};

	return (
		<AdminLayout>
			<div>
				<div className="uk-grid">
					<div className="uk-width-1-4">
						<label>Nombre</label>
						<IMaskInput
							type="text"
							mask={'[aaaaaaaaaaaaaaaaaaaaaaa]'}
							radix="."
							id="firstName"
							onAccept={handleOnChange}
							unmask={true}
							value={localStorage.getItem('firstName')}
							autoComplete="off"
						/>
						{/* <input id='firstName' type="text" placeholder="" defaultValue={localStorage.getItem('firstName')} onChange={handleOnChange} className="uk-width-1-1" /> */}
					</div>
					<div className="uk-width-1-4">
						<label>Apellido</label>
						<IMaskInput
							type="text"
							mask={'[aaaaaaaaaaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaaaaaaa]'}
							radix="."
							id="lastName"
							onAccept={handleOnChange}
							unmask={true}
							value={localStorage.getItem('lastName')}
							autoComplete="off"
						/>
						{/* <input id="lastName" type="text" placeholder="" value={localStorage.getItem('lastName')} className="uk-width-1-1" /> */}
					</div>
					<div className="uk-width-1-4">
						<label>Celular</label>
						<IMaskInput
							type="text"
							mask={'(000)000-0000'}
							radix="."
							id="mobilePhoneNumber"
							onAccept={handleOnChange}
							unmask={true}
							value={localStorage.getItem('mobilePhoneNumber')}
							autoComplete="off"
						/>
						{/* <input id="mobilePhoneNumber" type="text" placeholder="" value={localStorage.getItem('mobilePhoneNumber')} className="uk-width-1-1" /> */}
					</div>
				</div>
				<br />
				<button onClick={(e) => handleUpdateInfo(e)} className="uk-button buttonUpdateAccount">
					Actualizar
				</button>
			</div>
		</AdminLayout>
	);
};

export default UserAdminProfile;
