import React, { useState, useEffect } from "react";
import { REACT_APP_API_URL } from "../../App";
import CssBaseline from "@material-ui/core/CssBaseline";
import EnhancedTable from "./EnhancedTable";
import AdminLayout from "../../Layout/AdminLayout";
import axios from "axios";
// import makeData from './makeData'
// import { Link } from "react-router-dom";
import JSPDF from "jspdf";
import autoTable from "jspdf-autotable";
import reportsLogo from "../../assets/images/logo-reports.png";
import deleteLogo from "../../assets/images/icon-actions-delete.png";
import shareLogo from "../../assets/images/icon-actions-share.png";
import editLogo from "../../assets/images/icon-actions-edit.png";
import downloadLogo from "../../assets/images/icon-actions-download.png";
import moment from "moment";

const Reports = () => {
  const getReport = async (id) => {
    console.log("Obtener info del reporte",id);
    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/core/v1/reports/${id}`,
        { headers }
      );
      console.log("Reportes: ",response.data);
    } catch (error) {
      console.log("error", error);
      setError(error);
    }
  }
  const editButton = async (id) => {
    console.log("Obtener info del reporte",id);
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/core/v1/reports/${id}`,
        {
          updated_by: localStorage.getItem("idUser"),
        },
        { headers }
      );
      console.log("Reportes: ",response.data);
    } catch (error) {
      console.log("error", error);
      setError(error);
    }
  }
  const deleteButton = async (id) => {
    console.log("Borrar reporte",id);
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/core/v1/reports/${id}`,
        {
          id:id,
          updated_by: localStorage.getItem("idUser"),
          status:204000,
        },
        { headers }
      );
    } catch (error) {
      console.log("error", error);
      setError(error);
    }
  }
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;

    const doc = new JSPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Titulo del Reporte";
    const headers = [
      ["nombre", "Fecha de creación", "Última modificación", "Creada por"],
    ];

    const table = data.map((value) => [
      value.name,
      value["created_at"],
      value["updated_at"],
      value['first_name'],
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: table,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Report.pdf");
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Nombre",
        accessor: "name",
      },
      {
        Header: "Fecha de creación",
        accessor: "created_at",
      },
      {
        Header: "Última modificación",
        accessor: "updated_at",
      },
      {
        Header: "Creada por",
        accessor: "first_name",
        // getProps: (state, rowInfo, column) => {
        //   console.log([state, rowInfo, column]);
        //   return {
        //     style: {
        //       color: 'red'
        //     }
        //   }
        // }
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <div>
              <span className="uk-margin-small-right" title="Compartir reporte">
                <img
                  style={{ width: "5%", cursor: "pointer", marginRight: "4%" }}
                  src={shareLogo}
                />
              </span>
              <span className="uk-margin-small-right" title="Editar reporte">
                <img
                  style={{ width: "5%", cursor: "pointer", marginRight: "4%" }}
                  src={editLogo}
                  onClick={ async () => await getReport(cell.row.original.id)}
                />
              </span>
              <span className="uk-margin-small-right" title="Eliminar reporte">
                <img
                  style={{ width: "5%", cursor: "pointer", marginRight: "4%" }}
                  src={deleteLogo}
                  onClick={ ()=> deleteButton(cell.row.original.id)}
                />
              </span>
              <span className="uk-margin-small-right" onClick={() => exportPDF()} title="Descargar reporte">
                <img
                  style={{ width: "5%", cursor: "pointer", marginRight: "4%" }}
                  src={downloadLogo}
                />
              </span>
            </div>
          </>
        ),
      },
    ],
    []
  );
  const [error, setError] = useState(null);
  const [data, setData] = React.useState([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
  };
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  useEffect(() => {
    const fetchReports = async () => {
      try {
        // setIsLoaded(false);
        const response = await axios.get(
          `${REACT_APP_API_URL}/core/v1/reports`,
          { headers }
        );
        console.log("Reportes: ",response.data);
        let formatedData = response.data.map( (element)=>{
          let newElement = element;
          newElement['first_name'] = element['user'][0]['first_name'];
          element['created_at'] = moment(element['created_at']).format('DD-MM-YYYY');
          element['updated_at'] = moment(element['updated_at']).format('DD-MM-YYYY');
          return newElement;
        });
        setData(formatedData);
        // setIsLoaded(true);
      } catch (error) {
        console.log("error", error);
        // setIsLoaded(true);
        setError(error);
      }
    };
  
    fetchReports();
  },[])
  
  return (
    <AdminLayout>
      <div className="uk-width-5-6@m">
        <div className="uk-padding" style={{ backgroundColor: "#EDF5FF" }}>
          <a
            onClick={(e) => e.preventDefault()}
            style={{
              color: "#152142",
              cursor: "inherit",
              fontFamily: "Montserrat",
              fontSize: "20px",
            }}
            className="uk-logo uk-text-bold"
          >
            <img src={reportsLogo} width={20} alt="" />
            &nbsp; REPORTES
          </a>
        </div>
        <div className="table-reports">
          <div className="uk-overflow-auto">
            <CssBaseline />
            <EnhancedTable
              columns={columns}
              data={data}
              setData={setData}
              pdfAction={exportPDF}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Reports;
