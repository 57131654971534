import React, { useState, useEffect, Fragment } from "reactn";
import Modal from "react-responsive-modal";
import UIkit from "uikit";
import swal from "sweetalert2";
import history from "../../browserHistory";
import "./style.css";
import AdminLayout from '../../Layout/AdminLayout'

export default function Whitelist(props) {
  const [modalOpen, setModalOpen] = useState(false);

  if (localStorage.getItem('token') == null) { history.push("/login"); }

  useEffect(() => {
    prepareUpload();
  });

  return (
    <Fragment>
      <AdminLayout>
        <div style={{ width: '20%' }} className="uk-width-expand whitelist uk-width-1-2@s">
          <div className="rule-menu uk-width-expand">
            <form className="uk-search uk-search-default uk-flex uk-width-1-3">
              <span data-uk-search-icon></span>
              <input className=" uk-search-input" type="search" placeholder="Search..." />
              <button
                onMouseDown={() => {
                  setModalOpen(true);
                }}
                className="uk-margin-left uk-button uk-button-secondary"
              >
                Upload
            </button>
            </form>
          </div>
          <div className="table-wrapper">
            <table className="file-table uk-table-hover uk-margin-top uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Type</th>
                  <th>Content</th>
                  <th>Updated</th>
                  <th>Updated by</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <FileRow
                  name={"Nombre ramita"}
                  id={1}
                  descripcion={"Descripcion"}
                  tipo="Pool"
                  contenido="Form"
                  updatedAt="11-11-19"
                  updatedBy="Rayner D. Los S."
                />
                <FileRow
                  name={"Nombre ramita"}
                  id={1}
                  descripcion={"Descripcion"}
                  tipo="Pool"
                  contenido="Form"
                  updatedAt="11-11-19"
                  updatedBy="Rayner D. Los S."
                />
                <FileRow
                  name={"Nombre ramita"}
                  id={1}
                  descripcion={"Descripcion"}
                  tipo="Pool"
                  contenido="Form"
                  updatedAt="11-11-19"
                  updatedBy="Rayner D. Los S."
                />
                <FileRow
                  name={"Nombre ramita"}
                  id={1}
                  descripcion={"Descripcion"}
                  tipo="Pool"
                  contenido="Form"
                  updatedAt="11-11-19"
                  updatedBy="Rayner D. Los S."
                />
                <FileRow
                  name={"Nombre ramita"}
                  id={1}
                  descripcion={"Descripcion"}
                  tipo="Pool"
                  contenido="Form"
                  updatedAt="11-11-19"
                  updatedBy="Rayner D. Los S."
                />

              </tbody>
            </table>

            <Modal
              modalId="whiteListModal"
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              center={true}
            >
              <div className="uk-width-expand uk-flex uk-flex-middle uk-flex-center ">
                <div
                  id="upload-drop"
                  className="center-card uk-padding uk-margin-top uk-card uk-card-secondary uk-text-large "
                >
                  <div className="js-upload uk-placeholder uk-text-center">
                    <span uk-icon="icon: cloud-upload"></span>
                    <span className="uk-text-middle">
                      Arrastra la lista blanca aquí o
                  </span>
                    <div className="js-upload" data-uk-form-custom>
                      <input type="file" />
                      <button
                        className="uk-button uk-button-default uk-margin-small-left"
                        type="button"
                        tabIndex="-1"
                      >
                        Selecciona
                    </button>
                    </div>
                  </div>

                  <progress
                    id="js-progressbar"
                    className="uk-progress"
                    value="0"
                    max="100"
                    hidden
                  ></progress>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </AdminLayout>
    </Fragment>
  );
}

function FileRow(props) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <tr>
      <td>{props.id}</td>
      <td>{props.tipo}</td>
      <td>{props.contenido}</td>
      <td>{props.updatedAt}</td>
      <td>{props.updatedBy}</td>
      <td>
        <span onMouseDown={() => { setModalOpen(true) }}
          className="blue-highlight uk-margin-small-left"
          uk-icon="info"
        ></span>
        <span
          onMouseDown={() => {
            swal.fire({
              title: "Are you sure?",
              text: "Once deleted, you will have to start over!",
              icon: "warning",
              buttons: true,
              dangerMode: true
            })
          }}
          className="red-highlight uk-margin-small-left"
          uk-icon="trash"
        ></span>
      </td>
      <Modal open={modalOpen} modalId="infoModal" onClose={() => setModalOpen(false)} center>
        <div className="uk-card uk-card-default profile-card">
          <div className="uk-card-header">
            <div className="uk-grid-small uk-flex-middle uk-flex uk-grid" >
              <div className="uk-width-expand">
                <h5 className="custom-card-title uk-card-title uk-margin-remove-bottom">{props.name}</h5>
              </div>
            </div>
          </div>

          <div className="uk-card-body uk-flex uk-flex-column uk-overflow-auto uk-height-large">
            <h2 className="uk-margin-bottom information-title">Información </h2>
            <p className="custom-description">{props.descripcion}</p>

          </div>
        </div>
      </Modal>
    </tr>
  );
}

function prepareUpload() {
  var bar = document.getElementById("js-progressbar");

  UIkit.upload(".js-upload", {
    url: "",
    multiple: true,

    beforeSend: function () {
      console.log("beforeSend", arguments);
    },
    beforeAll: function () {
      console.log("beforeAll", arguments);
    },
    load: function () {
      console.log("load", arguments);
    },
    error: function () {
      console.log("error", arguments);
    },
    complete: function () {
      console.log("complete", arguments);
    },

    loadStart: function (e) {
      console.log("loadStart", arguments);

      bar.removeAttribute("hidden");
      bar.max = e.total;
      bar.value = e.loaded;
    },

    progress: function (e) {
      console.log("progress", arguments);

      bar.max = e.total;
      bar.value = e.loaded;
    },

    loadEnd: function (e) {
      console.log("loadEnd", arguments);

      bar.max = e.total;
      bar.value = e.loaded;
    },

    completeAll: function () {
      console.log("completeAll", arguments);

      setTimeout(function () {
        bar.setAttribute("hidden", "hidden");
      }, 1000);

      alert("Upload Completed");
    }
  });
}
