import React from "react";
import { Link, useLocation } from "react-router-dom";


const NotFound = () => (
  <div className="uk-position-center uk-text-center">
    <h1 className="uk-heading-medium">
      No match for - <code>{window.location.pathname}</code> - Not Found!
    </h1>
    <Link rel="preload" to="/admin">Go Back!</Link>
  </div>
);

export default NotFound;
