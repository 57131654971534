import React from "reactn";
import Header from "../components/Header";
import SideBar from "../components/Sidebar";

const AdminLayout = ({ children }) => {
  return (
    <main className="admin">
      <Header />
      <SideBar>{children}</SideBar>
    </main>
  );
};

export default AdminLayout;
