import React, { useEffect, useState } from "reactn";
import { Link } from "react-router-dom";
import AdminLayout from "../Layout/AdminLayout";
import find from "lodash/find";
import head from "lodash/head";
import ContentLoader from "react-content-loader";
import axios from "axios";
import Paginate from "react-paginate";
import ReactTooltip from "react-tooltip";
import chart from "../assets/images/research.svg";
import { REACT_APP_API_URL } from "./../App";
import history from "../browserHistory";
import swal from "sweetalert2";
import jwt from "jsonwebtoken";
import Swal from "sweetalert2";
require("dotenv").config();

const TableFormBuilder = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
  };
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        setIsLoading(true);
        let res = await axios.get(
          `${REACT_APP_API_URL}/core/v1/forms/definitions`,
          { headers }
        );
         setItems(res.data[0].metas);
         setIsLoading(false);
      } catch (err) {
        await swal
          .fire({
            icon: "error",
            title: "Oops...",
            text: "Tu sesión ha caducado, inicia sesión nuevamente!",
            allowOutsideClick: false,
            allowEscapeKey: false,
            backdrop: `
						rgb(91, 91, 91)
						center left
						no-repeat
  					`,
          })
          .then((notif) => {
            if (notif.value) {
              localStorage.removeItem("email");
              localStorage.removeItem("tokenAdmin");
            }
          });
         history.push("/");
         history.go();
      }
    };
    fetchItems();
  }, []);


 

  // 19980000
  // 216000000
  
  // setTimeout(() => {
  //   const Toast = Swal.mixin({
  //     toast: true,
  //     position: "center",
  //     showConfirmButton: false,
  //     timer: 3000,
  //     timerProgressBar: true,
  //     didOpen: (toast) => {
  //       toast.addEventListener("mouseenter", Swal.stopTimer);
  //       toast.addEventListener("mouseleave", Swal.resumeTimer);
  //     },
  //   });

  //   Toast.fire({
  //     icon: "info",
  //     title: "Hola, en 5 minutos se cerrará la sesión.",
  //   });
  // }, 19980000);

  // setTimeout(()  => {
  //   console.log('se abrio')
  //   swal
  //   .fire({
  //     icon: "error",
  //     title: "Sesión caducada.",
  //     text: "Tu sesión ha caducado, inicia sesión nuevamente.",
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //     backdrop: `
  //     rgb(91, 91, 91)
  //     center left
  //     no-repeat
  //     `,
  //   })
  //   .then((notif) => {
  //     if (notif.value) {
  //       localStorage.removeItem("email");
  //       localStorage.removeItem("tokenAdmin");
  //       history.push("/");
  //       history.go();
  //     }
  //   });
  // }, dateToken);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = items.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginates = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) {
    return (
      <AdminLayout>
        <div className="uk-width-5-6">
          <ContentLoader
            speed={2}
            width={1700}
            height={300}
            viewBox="-400 0 2000 1100"
            backgroundColor="#eaeced"
            foregroundColor="#ffffff"
          >
            <rect x="51" y="45" rx="3" ry="3" width="906" height="17" />
            <circle cx="879" cy="123" r="11" />
            <circle cx="914" cy="123" r="11" />
            <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
            <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
            <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
            <rect x="55" y="155" rx="3" ry="3" width="897" height="2" />
            <circle cx="880" cy="184" r="11" />
            <circle cx="915" cy="184" r="11" />
            <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
            <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
            <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
            <rect x="56" y="216" rx="3" ry="3" width="897" height="2" />
            <circle cx="881" cy="242" r="11" />
            <circle cx="916" cy="242" r="11" />
            <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
            <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
            <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
            <rect x="57" y="274" rx="3" ry="3" width="897" height="2" />
            <circle cx="882" cy="303" r="11" />
            <circle cx="917" cy="303" r="11" />
            <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
            <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
            <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
            <rect x="58" y="335" rx="3" ry="3" width="897" height="2" />
            <circle cx="881" cy="363" r="11" />
            <circle cx="916" cy="363" r="11" />
            <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
            <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
            <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
            <rect x="57" y="395" rx="3" ry="3" width="897" height="2" />
            <circle cx="882" cy="424" r="11" />
            <circle cx="917" cy="424" r="11" />
            <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
            <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
            <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
            <rect x="55" y="453" rx="3" ry="3" width="897" height="2" />
            <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
            <rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
            <circle cx="882" cy="484" r="11" />
            <circle cx="917" cy="484" r="11" />
            <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
            <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
            <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
            <rect x="55" y="513" rx="3" ry="3" width="897" height="2" />
            <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
            <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
            <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
            <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
            <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
            <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
          </ContentLoader>
        </div>
      </AdminLayout>
    );
  }

  function isoDateToShortDate(date) {
    const dateFormatted = new Date(date);
    return (
      dateFormatted.getFullYear() +
      "-" +
      (dateFormatted.getMonth() + 1) +
      "-" +
      dateFormatted.getDate()
    );
  }

  function countQuestions(c, key) {
    let keyForm = find(c, (f) => f.key == key);
    if (keyForm) {
      let component = head(keyForm.properties.definition.components);
      if (typeof keyForm == "undefined") {
        return 0;
      } else {
        return keyForm.properties.definition.components.length;
      }
    } else {
      return 0;
    }
  }

  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    await setCurrentPage(selectedPage + 1);
    await setPage(selectedPage);
  };

  return (
    <AdminLayout>
      <div className="uk-width-5-6">
        <div className="table-wrapper">
          <div className="table-title">
            <ReactTooltip id="chart" place="bottom" effect="solid">
              Ver estadísticas asociados a este formulario.
            </ReactTooltip>
            <ReactTooltip id="rules" place="bottom" effect="solid">
              Ver reglas asociados a este formulario.
            </ReactTooltip>
            <div data-uk-grid>
              <div className="uk-width-1-2">
                {/* <h3 style={{color: 'white'}} className="uk-text-bold">Manage Pools</h3> */}
              </div>
              <div className="uk-width-1-2">
                <Link rel="preload" to="/admin/formhub/new">
                  <span className="uk-badge badgeSurvey" uk-icon="icon: plus">
                    ¡Nueva encuesta!
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="uk-overflow-auto">
            <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Páginas</th>
                  <th>Fecha de creación</th>
                  <th>Creado por</th>
                  <th>Estatus</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts == 0 ? (
                  <div>
                    <p>No hay encuestas para mostrar.</p>
                  </div>
                ) : (
                  currentPosts.map((data, index) => (
                    <tr key={index}>
                      {/* <Link rel="preload"
                        className="uk-link-reset"
                        style={{ color: "black" }}
                        to={`/admin/formhub/update/${data.key}`}
                      > */}
                      <td
                        onClick={() =>
                          (document.location.href = `/admin/formhub/update/${data.key}`)
                        }
                        className="pointer uk-table-link"
                      >
                        {data.key}
                      </td>
                      {/* </Link> */}
                      <td>{countQuestions(items, data.key)}</td>
                      <td>{isoDateToShortDate(data.updated_at)}</td>
                      <td>Admin 1</td>
                      <td>
                        {data.status == 200000 ? "Habilitado" : "Deshabilitado"}
                      </td>
                      <td className="uk-text-nowrap">
                        <Link
                          rel="preload"
                          style={{ color: "black" }}
                          to="/admin/rules"
                        >
                          <span
                            data-tip
                            data-for="rules"
                            className="uk-margin-small-right"
                            uk-icon="icon: settings"
                          />
                        </Link>
                        <Link
                          rel="preload"
                          style={{ color: "black" }}
                          to={`/admin/charts/${data.key}`}
                        >
                          <span
                            data-tip
                            data-for="chart"
                            className="uk-margin-small-right"
                          >
                            <img width={"17px"} src={chart} />
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div
              className="clearfix"
              style={
                currentPosts == 0 ? { display: "none" } : { display: "block" }
              }
            >
              <div className="hint-text">
                Mostrando <b>{currentPosts.length}</b> de <b>{items.length}</b>{" "}
                encuestas.
              </div>
              <Paginate
                previousLabel={currentPage == 0 ? "" : "Atrás"}
                nextLabel={"Siguiente"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={items.length / postsPerPage}
                pageRangeDisplayed={postsPerPage}
                containerClassName={"pagination"}
                activeClassName={"active"}
                onPageChange={(e) => handlePageClick(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default TableFormBuilder;
