import React, { useState, useEffect, useReducer } from 'reactn';
import axios from 'axios';
import UIkit from 'uikit';
import { FormBuilder } from 'react-formio';
import swal from 'sweetalert2';
import jwt from 'jsonwebtoken';
import history from '../../browserHistory';
import { REACT_APP_API_URL } from '../../App';
require('dotenv').config();
const validator = require('validator');

let headersForm = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${localStorage.getItem('tokenAdmin')}`
	}
};

const saveForm = async (form) => {
	return await axios.post(`${REACT_APP_API_URL}/core/v1/forms/definitions/`, form, headersForm);
};

let initialState = {
	display: 'wizard',
	components: {}
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'changeDisplay':
			return {
				display: action.display
			};
		case 'changeComponents':
			return {
				...state,
				components: action.components
			};
		default:
			return state;
	}
};
let definitionToSave = {};
const FormHubNew = () => {
	const options = {
		builder: {
			basic: false,
			advanced: false,
			layout: false,
			data: false,
			premium: false,
			profileComponents: {
				title: 'Profile Components',
				default: true,
				weight: 0,
				components: {
					textfield: true,
					checkbox: true,
					radio: true,
					button: true,
					selectboxes: true,
					hidden: true
				}
			}
		}
	};
	const [definition, dispatch] = React.useReducer(reducer, initialState);
	const [reward, setReward] = useState({ "date": "", "reward": "", duration: "" })
	const [surveyTitle, setSurveyTitle] = useState("")
	let handleOnDisplay = (e) => {
		dispatch({
			type: 'changeDisplay',
			display: e.target.value
		});
	};

	let handleOnSubmit = async (e) => {
		let key = document.querySelector('#input-key');
		let survey_title = surveyTitle

		if (validator.isEmpty(key.value)) {
			document.querySelector('#input-key').style.borderColor = 'red';
			UIkit.notification({
				message: 'Campo de llave vacio!',
				status: 'danger',
				pos: 'top-right'
			});
			return false;
		} else if (!key.value.indexOf(' ') == 1) {
			UIkit.notification({
				message: 'Campo de llave vacio!',
				status: 'danger',
				pos: 'top-right'
			});
			return false;
		} else if (validator.isEmpty(survey_title)) {
			document.querySelector('#input-name').style.borderColor = 'red';
			UIkit.notification({
				message: 'Campo de nombre vacio!!',
				status: 'danger',
				pos: 'top-right'
			});
			return false;
		} else if (!survey_title.indexOf(' ') == 1) {
			UIkit.notification({
				message: 'Campo de nombre vacio!',
				status: 'danger',
				pos: 'top-right'
			});
			return false;
		} else {
			key = key.value.split(' ').join('_');
			let formProfile = Object.assign(
				{
					key: key
				},
				{
					properties: {
						scope: 'anonymous',
						reward,
						definition: definitionToSave
					}
				}
			);
			formProfile.survey_title = surveyTitle
			document.querySelector('#input-key').style.borderColor = '#e5e5e5';
			document.querySelector('#input-key').value = '';
			document.querySelector('#input-name').style.borderColor = '#e5e5e5';
			document.querySelector('#input-name').value = '';
			// console.log('formProfile: ', formProfile);
			await saveForm(formProfile);
			await UIkit.notification({
				message: 'Saved!',
				status: 'success',
				pos: 'top-right'
			});
			await history.push('/admin');
			await history.go();
		}
	};
	//(The forbiden)si lo quitas empieza el loop infinito
	useEffect(
		() => {
			// console.log({ definition });

			let validateSession = async () => {
				try {
					let isExpired = false;
					const token = localStorage.getItem('tokenAdmin');
					let decodedToken = jwt.decode(token, { complete: true });
					let dateToken = new Date(decodedToken.payload.exp * 10000);
					let dateNow = new Date();

					if (dateToken < dateNow.getTime()) {
						isExpired = true;
						await swal.fire({
							icon: 'error',
							title: 'Sesión caducada.',
							text: 'Tu sesión ha caducado, inicia sesión nuevamente!',
							allowOutsideClick: false,
							allowEscapeKey: false,
							backdrop: `
              rgb(91, 91, 91)
              center left
              no-repeat
              `
						})
						 history.push('/')
						 history.go()
					} else {
						isExpired = false;
					}
					// console.log(isExpired);
				} catch (error) {
					await swal.fire({
						icon: 'error',
						title: 'Sesión caducada.',
						text: 'Tu sesión ha caducado, inicia sesión nuevamente!',
						allowOutsideClick: false,
						allowEscapeKey: false,
						backdrop: `
              rgb(91, 91, 91)
              center left
              no-repeat
              `
					})
					history.push('/');
					history.go();
				}
			};
			validateSession();
		},
		[definition]
	);
	return (
		<div className="">
			<div className="uk-container uk-width-1-1 uk-padding-remove">
				<div className="uk-grid uk-margin-remove rule-menu uk-width-1-1 uk-padding-small">
					<div>
						<select
							className="uk-select"
							name="displayForm"
							id="displayForm"
							value={definition.display}
							onChange={(e) => {
								handleOnDisplay(e);
							}}
						>
							<option value="form"> Form </option> <option value="wizard"> Wizard </option>
							{/* <option value="pdf">PDF</option> */}
						</select>
					</div>
					<div>
						<label
							style={{
								marginTop: '3px',
								marginRight: '-5px'
							}}
							className="uk-label uk-align-right labelKey"
						>

							Nombre
						</label>
					</div>
					<div>
						<input
							style={{
								inlineSize: '243px',
								textAlign: 'center'
							}}
							value={surveyTitle}
							type="text"
							id="input-name"
							className="uk-input uk-align-center"
							placeholder="Nombra tu encuesta"
							onChange={(e) => {
								setSurveyTitle(e.target.value)
							}}
						/>
					</div>
					<div>
						<label
							style={{
								marginTop: '3px',
								marginRight: '-5px'
							}}
							className="uk-label uk-align-right labelKey"
						>

							llave
						</label>
					</div>
					<div>
						<input
							style={{
								inlineSize: '243px',
								textAlign: 'center'
							}}
							type="text"
							id="input-key"
							className="uk-input uk-align-center"
							placeholder="Llave unica de encuesta"
						/>
					</div>
					<div className="uk-flex">
						<button
							type="submit"
							className="uk-button uk-button-primary"
							onClick={(e) => {
								handleOnSubmit();
							}}
						>
							Save
						</button>
					</div>
				</div>
				<div className="uk-margin-large-top uk-margin-bottom">
					<FormBuilder
						form={definition}
						onChange={(change) => {
							definitionToSave = change;
						}}
					/>
				</div>
				<div className="uk-margin-large-top uk-margin-bottom">
					<textarea
						name="UNIQUE_ID_OF_DIV"
						id=""
						cols="30"
						rows="10"
						onChange={(change) => {
							setReward(change.target.value)
						}}
					>
						{JSON.stringify(reward)}
					</textarea>
				</div>
			</div>
		</div>
	);
};

export default FormHubNew;
