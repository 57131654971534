// eslint-disable-next-line
import React, { addCallback, useDispatch, useState, useGlobal } from 'reactn';
// import Particles from 'react-particles-js';
// import particlesOptions from './assets/particlesOptions.json';
// import PinInput from 'react-pin-input';
import Modal from 'react-responsive-modal';
import './style.css';
import 'uikit';
import InputOTP from '../../components/InputOTP';
import '../../components/InputStyle.css';
import history from '../../browserHistory';
import validator from 'validator';
import emailAnimation from '../../assets/images/emailAnimation.gif';
import UIkit from 'uikit';
import swal from 'sweetalert2';
import { REACT_APP_API_URL } from '../../App';
import axios from 'axios';

export default function Login(props) {
	const [ verify, setVerify ] = useState({});
	const verifyAccountExist = useDispatch('verifyAccountExist');
	const validateOTP = useDispatch('validateOTP');
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const [ accountExist, setAccountExist ] = useGlobal('accountExist');
	const hideMenuCss = '.small-menu-wrapper{display:none}';

	// if( localStorage.getItem('token') ){ history.push("/usuarios"); }
	let axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault()
		let emailInput = document.querySelector('#login_email').value;
		const validatorEmail = validator.isEmail(emailInput);
		if (validatorEmail) {
			localStorage.setItem('email', emailInput);
			await axios
				.post(`${REACT_APP_API_URL}/core/v1/authentication/init`, { email: emailInput }, axiosConfig)
				.then((res) => {
					setAccountExist({ accountExise: true });
					setVerify(emailInput);
				})
				.catch((err) => {
					UIkit.notification({ message: 'Usuario no registrado.', status: 'danger' });
				});
		} else {
			UIkit.notification({ message: 'Email no es válido.', status: 'danger' });
		}
	};

	const handleReSend = async () => {
		await axios
			.post(`${REACT_APP_API_URL}/core/v1/authentication/init`, {
				email: localStorage.getItem('email')
			})
			.then((res) => {
				swal.fire({
					title: 'Se le ha envíado otro código, por favor revise.',
					text: 'Recuerde que su código tiene una duración de 5 minutos.',
					imageUrl: emailAnimation,
					imageWidth: '300px'
				});
			})
			.catch((err) => console.error(err));
	};

	addCallback((global) => {
		if (global.accountExist) {
			openModal();
		}
	});

	const openModal = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setAccountExist({ accountExist: false });
		setIsModalOpen(false);
	};

	const handlePinComplete = async () => {
		let code = '';
		document.querySelectorAll('#pincode-input-text').forEach((el, i) => {
			code += el.value;
		});
		await axios
			.post(`${REACT_APP_API_URL}/core/v1/authentication/verify`, { email: verify, token: code })
			.then((res) => {
				let token = res.data.authorization;
				localStorage.setItem('tokenAdmin', token);
				history.push('/admin');
				history.go();
			})
			.catch((err) => {
				UIkit.notification({ message: 'Código incorrecto o expiró.', status: 'danger' });
			});
	};

	return (
		<div className="uk-text-center uk-flex uk-width-1-1 uk-margin-remove uk-padding-remove">
			<style>{hideMenuCss}</style>
			{/* <Particles
				params={particlesOptions}
				className="uk-width-2-3 uk-height-viewport uk-card-default uk-card-secondary background-particle"
			/> */}

			<div className='uk-width-2-3@m uk-visible@m uk-height-viewport uk-card-default uk-card-secondary background-particle'></div>
			<div className="uk-width-1-3@m uk-height-viewport uk-card-default uk-flex uk-flex-middle">
				<form id="" className="uk-form-vertical uk-margin-auto" method="post" action="./" data-colspacing="0">
					{/* <label className="uk-heading-small uk-text-left uk-margin-large uk-text-italic" >Login</label> */}
					<ul
						className=" uk-grid-collapse uk-grid-match uk-grid uk-grid-stack"
						uk-grid="uk-text-large"
						uk-height-match="target: > .:not(.StateCollapsed) > .Content"
					>
						<li className="uk-width-1-1 uk-first-column " id="wrap_login_name">
							<label className="uk-margin-top uk-text-lead  uk-form-label">User email</label>
							<div className=" uk-form-controls">
								<div className="uk-inline uk-width-2-3">
									<input
										id="login_email"
										className="uk-input uk-width-1-1"
										name="login_name"
										type="text"
										onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
										maxLength="60"
									/>
								</div>
							</div>
						</li>
{/* "return (event.keyCode!=13)" */}
						<div className="uk-margin-top  uk-flex uk-flex-center uk-width-1-3 uk-margin-auto uk-form-controls">
							<div
								id="_login_submit"
								className="uk-button uk-button-secondary "
								name="login_submit"
								value="Login"
								onMouseDown={handleSubmit}
							>
								<span className="login-button">Login</span>
							</div>
						</div>
					</ul>
				</form>
			</div>
			<Modal open={isModalOpen ? isModalOpen : false} onClose={handleModalClose} center>
				{/* <h3>Inserta el codigo el su correo</h3> */}
				<div id="wrapper">
					<div id="dialog">
						<h3>Ingrese el código de verificación de 6 dígitos que le enviamos por correo:</h3>
						<div className=" uk-margin-down uk-position-center">
							<InputOTP id={'pincode-input-text'} />
						</div>
						<br />
						<div>
							<button onClick={handlePinComplete} className="uk-button buttonLoginAdmin">
								Iniciar sesión
							</button>
						</div>
						<div className="uk-margin-top uk-margin code">
							¿No recibiste el código?
							<br />
							<a onMouseDown={handleReSend} className="uk-text-danger" href="#">
								Reenviar código
							</a>
							<br />
							{/* <a href="#">Change email</a> */}
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
