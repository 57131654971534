import { addCallback, addReducer, setGlobal } from "reactn";
import { REACT_APP_API_URL } from "./App";
import history from "./browserHistory";
import UIkit from "uikit";
import axios from "axios";
require('dotenv').config()


addReducer("setError", (error = "") => ({
  error: error
}));

addReducer("verifyAccountExist", global => {
  axios
    .post(`${REACT_APP_API_URL}/core/v1/authentication/init`, global.verify)
    .then((req) => {
      if (req.status === 200 || req.status === 204) {
        setGlobal({ accountExist: true });
      } else {
        UIkit.notification({ message: 'Usuario no existe', status: 'danger' })
      }
    }).catch(() => {
      UIkit.notification({ message: 'Usuario no existe', status: 'danger' });
    })
});


addReducer(
  "validateOTP",
  global => {
    axios
      .post(`${REACT_APP_API_URL}/core/v1/authentication/verify`, global.verify)
      .then(async (res) => {
        let token = res.data.authorization;
        if (res.status == 200) {
          await localStorage.setItem('token', token);
          await history.push('/dashboard');
          await history.go()
        }
      })
      .catch(err => {
        UIkit.notification({ message: 'Por favor verifica el token', status: 'danger' });
      });
  }
);


addReducer(
  "getAccountInfo",
  global =>
    new Promise(resolve => {
      if (global.session === null || global.session === undefined) {
        history.push("/");
        return false;
      }

    })
);    