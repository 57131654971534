import React, { useState, useEffect } from "react";

function Table({ answers }) {

  console.log(answers)
  return (
    <li>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "10px",
        }}
        className="uk-flex uk-border-rounded uk-margin-bottom uk-flex-column"
      >
        <div className="uk-margin-auto-left">
          <nav data-uk-navbar="mode: click">
            <div className="uk-navbar-left">
              <ul class="uk-navbar-nav">
                <li>
                  <span
                    title="Ajustes"
                    style={{ cursor: "pointer" }}
                    uk-icon="icon: cog"
                  >
                    <a href="#"></a>
                  </span>

                  <div class="uk-navbar-dropdown">
                    <ul
                      data-uk-nav
                      class="uk-nav uk-navbar-dropdown-nav uk-nav-parent-icon"
                    >
                      <li class="uk-active">
                        <a href="#">Menú de opciones</a>
                      </li>
                      <li className="uk-parent">
                        <a href="#">
                          <span
                            class="uk-margin-small-right"
                            uk-icon="icon: thumbnails"
                          ></span>
                          Elegir gráficos
                        </a>
                        <ul class="uk-nav-sub">
                          <li>
                            <a href="#">Gráfico 1</a>
                          </li>
                          <li>
                            <a href="#">Gráfico 2</a>
                          </li>
                          <li>
                            <a href="#">Gráfico 3</a>
                          </li>
                          <li>
                            <a href="#">Gráfico 4</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#">
                          <span
                            class="uk-margin-small-right"
                            uk-icon="icon: file-edit"
                          ></span>
                          Editar
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span
                            class="uk-margin-small-right"
                            uk-icon="icon: download"
                          ></span>
                          Exportar a Excel
                        </a>
                      </li>
                      <li>
                        <a
                          // onClick={() =>
                          //   handleDelete(answ.key)
                          // }
                          href="#"
                        >
                          <span
                            class="uk-margin-small-right"
                            uk-icon="icon: trash"
                          ></span>
                          Eliminar
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        
           {/* <div className="uk-text-center">
             <p className="uk-margin-remove">
               <strong>{answ[0].label}</strong>
             </p>
           </div> */}
          
            <div
              style={{ paddingRight: "20px" }}
              className="uk-overflow-auto uk-height-medium"
            >
              <table class="uk-table uk-table-divider uk-table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Total</th>
                    <th>%</th>
                    {/* <th className="uk-margin-remove">
                      <dl class="uk-description-list uk-text-capitalize">
                        <dt className="uk-text-nowrap">% Entrevistados</dt>
                        <dd title={answ.totalAnswer} className="uk-text-center">
                          Base: {answ.totalAnswer}
                        </dd>
                      </dl>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {answers.groupByAnswer.map((value, i) => (
                    <tr>
                      {console.log(value)}
                      <td></td>
                      <td className="uk-width-expand">
                        <span> &nbsp; {value.answer} </span>
                      </td>
                      <td>
                        <strong>{value.answer_count}</strong>
                      </td>

                      <td
                        title={
                          (
                            (value.answer_count / answers.totalAnswersCount) *
                            100
                          ).toFixed(2) + "%"
                        }
                      >
                        <strong>
                          {(
                            (value.answer_count / answers.totalAnswersCount) *
                            100
                          ).toFixed(2) + "%"}
                        </strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <hr />
            <div className="uk-flex uk-flex-between uk-margin-top">
              <span>
                &nbsp; Total de respuestas: <strong>{answers.totalAnswersCount}</strong>
              </span>
              {/* <span>
                &nbsp; Total de encuestados: <strong>23,424</strong>
              </span> */}
            </div>

      </div>
    </li>
  );
}

export default Table;
