import React, { useEffect, useState } from 'reactn';
import QueryBuilder from 'react-querybuilder';
import swal from 'sweetalert2';
import './style.css';
import { createBrowserHistory } from 'history';
import remove from 'lodash/remove';
import AdminLayout from '../../Layout/AdminLayout';
import { REACT_APP_API_URL } from '../../App';
import validator from 'validator';
import UIkit from 'uikit';
import axios from 'axios';
const history = createBrowserHistory();

const headers = {
	"Content-Type": "application/json",
	"Authorization": `Bearer ${localStorage.getItem('tokenAdmin')}`
}

function Rule() {
	// const [session, setSession] = useGlobal('session');
	const [engineRules, setEngineRules] = useState(null);
	const [JsonRule, setJsonRule] = useState({});
	const [CheckboxTable, setCheckboxTable] = useState(false);
	const [, setRule] = useState({});
	const [Fields, setFields] = useState([]);
	const [Forms, setForms] = useState([]);
	const [QueryForSave, setQueryBuilderForSave] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [surveyList, setSurveyList] = useState([]);
	const [key, setKey] = useState([]);
	const [JsonRuleQuery, setJsonRuleQuery] = useState([]);

	// if (localStorage.getItem('tokenAdmin') == null) {
	// 	history.push('/login');
	// }
	useEffect(() => {
		//facts
		const fetchFacts = async () => {
			try {
				setIsLoading(true);
				let facts = await axios.post(`${REACT_APP_API_URL}/core/v1/authentication/facts`, {}, { headers });
				await setFields(facts.data);
				await setIsLoading(false);
			} catch (err) {
				await swal.fire({
					icon: 'error',
					title: 'Sesión caducada.',
					text: 'Tu sesión ha caducado, inicia sesión nuevamente!',
					allowOutsideClick: false,
					allowEscapeKey: false,
					backdrop: `
				rgb(91, 91, 91)
				center left
				no-repeat
			  `
				});
				await history.push('/');
				await history.go();
			}
		};
		fetchFacts();

		//definitions
		const fetchDefinitions = async () => {
			try {
				setIsLoading(true);
				let facts = await axios.get(`${REACT_APP_API_URL}/core/v1/forms/definitions`, { headers });
				await setForms(facts.data[0].metas);
				await setIsLoading(false);
			} catch (err) {
				await swal.fire({
					icon: 'error',
					title: 'Sesión caducada.',
					text: 'Tu sesión ha caducado, inicia sesión nuevamente!',
					allowOutsideClick: false,
					allowEscapeKey: false,
					backdrop: `
							rgb(91, 91, 91)
							center left
							no-repeat
						  `
				});
				await history.push('/');
				await history.go();
			}
		};
		fetchDefinitions();

		//rules
		const fetchRules = async () => {
			try {
				setIsLoading(true);
				let rules = await axios.get(`${REACT_APP_API_URL}/core/v1/rules	`, { headers });
				await setRule(rules.data.rules[0].properties);
				await setIsLoading(false);
			} catch (err) {
				await swal.fire({
					icon: 'error',
					title: 'Sesión caducada.',
					text: 'Tu sesión ha caducado, inicia sesión nuevamente!',
					allowOutsideClick: false,
					allowEscapeKey: false,
					backdrop: `
						rgb(91, 91, 91)
						center left
						no-repeat
  					`
				});
				await history.push('/');
				await history.go();
			}
		};
		fetchRules();
	}, []);

	if (error != null) {
		// console.log(error);
	}
	if (isLoading) {
		return (
			<AdminLayout>
				<p> loading... </p>
			</AdminLayout>
		);
	}
	const operators = [
		{ name: 'equal', label: 'igual' },
		{ name: 'notEqual', label: 'diferente' },
		{ name: 'lessThan', label: 'menor que' },
		{ name: 'greaterThan', label: 'mayor que' },
		{ name: 'lessThanInclusive', label: 'menor o igual a' },
		{ name: 'greaterThanInclusive', label: 'mayor o igual a' }
	];
	const handleReset = () => {
		swal
			.fire({
				title: 'Are you sure?',
				text: 'Once deleted, you will have to start over!',
				icon: 'warning',
				buttons: true,
				dangerMode: true
			})
			.then((willDelete) => {
				if (willDelete) {
					setEngineRules({ rules: [], combinator: 'and', not: false });
				}
			});
	};
	function toJsonRule(obj, str) {
		setJsonRuleQuery(obj);
		// console.log('obj: ', obj);
		let arr = obj;
		if (arr.combinator) {
			if (arr.combinator === 'and') {
				arr.all = arr.rules;
			} else if (arr.combinator === 'or') {
				arr.any = arr.rules;
			} else {
				// console.log('ninguno');
			}

			delete arr.id;
			delete arr.rules;
			delete arr.combinator;
			delete arr.not;

			if (arr.any) {
				arr.any.forEach((element) => {
					toJsonRule(element, 'any');
				});
			} else if (arr.all) {
				arr.all.forEach((element) => {
					toJsonRule(element, 'all');
				});
			}
		} else if (arr.fact) {
			delete arr.id;
			arr[str] = arr.rules;
			if (arr.any) {
				arr.any.forEach((element) => {
					toJsonRule(element, 'any');
				});
			} else if (arr.all) {
				arr.all.forEach((element) => {
					toJsonRule(element, 'all');
				});
			}
		}
		let translate = JSON.stringify(arr);
		translate = translate.replace(/field/gi, 'fact');
		arr = JSON.parse(translate);
		setJsonRule(arr);
	}
	const saveRule = async (q) => {
		let keyRule = document.querySelector('#input-key').value;
		if (validator.isEmpty(keyRule)) {
			document.querySelector('#input-key').style.borderColor = 'red';
			UIkit.notification({
				message: 'Campo Key está vacio, por favor llenar!',
				status: 'warning',
				pos: 'top-right'
			});
			return false;
		}

		if (!CheckboxTable) {
			await UIkit.notification({
				message: 'Selecciona de la tabla de encuestas al menos un formulario para que la regla sea aplicada.',
				status: 'warning',
				pos: 'top-right'
			});
			return false;
		} else if (!keyRule.indexOf(' ') == 1) {
			await UIkit.notification({
				message: 'Input Key is empty, please fill!',
				status: 'warning',
				pos: 'top-right',
				border: 'black'
			});
			return false;
		} else {
			keyRule = keyRule.split(' ').join('_');
			swal
				.fire({
					title: '¿Estás seguro?',
					text: 'Una vez guardado, esto afecta todas las encuestas compatibles a esta regla.',
					allowOutsideClick: false,
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Save!',
					icon: 'warning'
				})
				.then((willSave) => {
					if (willSave.value) {

						// if (JsonRule[`${Object.keys(JsonRule)[0]}`].length > 0) {
						let body = {
							key: keyRule,
							properties: {
								conditions: QueryForSave,
								metadata: JsonRuleQuery,
								event: { type: 'event_poll_available_surveys', params: { surveys: surveyList } }
							}
						};
						document.querySelector('#input-key').style.borderColor = '#e5e5e5';
						document.querySelector('#input-key').value = '';
						fetch(`${REACT_APP_API_URL}/core/v1/rules`, {
							method: 'POST',
							headers,
							body: JSON.stringify(body)
						}).then((res) => {
							if (res) {
								swal.fire({
									position: 'center',
									icon: 'success',
									title: 'Tus configuraciones han sido guardadas.',
									showConfirmButton: false,
									timer: 1500,
								}).then((ok) => {
									if (ok) {
										history.push('/admin/rules')
										history.go()
									}
								});;
							}
						})

						// } else {
						// 	console.log(JsonRule);
						// }
					}
				});
		}
	};
	const HandleSelectedSurvey = (data) => {
		console.log(surveyList)
		let list = surveyList;
		let survey = data.target;
		let index = list.indexOf(survey.value);
		let tableSurvey = document.getElementById('table-survey').getElementsByTagName('input');

		let is_checked = false;
		for (let i = 0; i < tableSurvey.length; i++) {
			if (tableSurvey[i].checked) {
				is_checked = true;
			}
		}

		if (is_checked) {
			setCheckboxTable(true)
		} 

		if (index > -1) {
			list.splice(index, 1)
		} else {
			list.push(survey.value);
			setSurveyList(list)
		}
	};
	return (
		<AdminLayout>
			<div className="uk-flex uk-flex-column uk-width-1-1">
				<div className="rule-menu uk-width-1-1">
					<button
						style={{ backgroundColor: 'blue' }}
						onClick={saveRule}
						className="uk-button uk-button-primary uk-margin-top uk-margin-left uk-margin-bottom uk-text-bold"
					>
						Guardar
					</button>
					<button
						onClick={handleReset}
						className="uk-button uk-button-danger uk-margin-top uk-margin-left uk-margin-bottom uk-text-bold"
					>
						Reset
					</button>
					<button
						style={{ backgroundColor: 'blue' }}
						className="uk-button uk-align-right uk-button-primary uk-margin-top uk-margin-right uk-margin-bottom uk-text-bold"
						uk-toggle="target: #modal-sections"
						type="button"
					>
						Encuestas
					</button>
					<div>
						<label className="labelKeyRules">
							Key
							<input maxLength={20} type="text" id="input-key" className="inputKeyRules" />
						</label>
					</div>
				</div>
				<div uk-grid="true">
					<div className="uk-margin-top uk-width-2-3">
						<QueryBuilder
							className="uk-margin-remove"
							operators={operators}
							fields={Fields}
							query={engineRules}
							onChange={(e) => saveRule(e)}
							onQueryChange={(q) => {
								setQueryBuilderForSave(q)
								// toJsonRule(q)
							}}
						/>
					</div>

					<br />

					<div className="uk-margin-top uk-width-1-3">
						<div className="uk-overflow-auto">
							<div id="modal-sections" uk-modal="true" bg-close="false">
								<div className="uk-modal-dialog">
									{/* <button className="uk-modal-close-default" type="button" uk-close>Cerrar</button> */}
									<div style={{ background: 'none' }} className="uk-modal-header">
										<h2
											style={{ fontSize: '32px' }}
											className="uk-modal-title uk-text-center uk-text-normal"
										>
											Selecciona al menos una encuesta.
										</h2>
									</div>
									<div className="uk-modal-body">
										<table
											id="table-survey"
											className="uk-table uk-table-hover uk-table-middle uk-table-divider"
										>
											{/* <thead>
									<tr>
										<th className="uk-table-shrink" />
										<th className="uk-table-shrink">Tabla de encuestas</th>
									</tr>
								</thead> */}
											<tbody>
												{Forms.map((form, i) => {
													return (
														<tr key={i + '-'}>
															<td>
																<input
																	style={{ display: 'inline-block' }}
																	className="uk-checkbox form-list-checked"
																	type="checkbox"
																	value={form.id}
																	name={form.key}
																	onChange={(element) => {
																		HandleSelectedSurvey(element);
																	}}
																/>
															</td>
															<td>{form.key}</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
									<hr />
									<div className="uk-modal-footer uk-text-right">
										<button
											style={{ backgroundColor: 'red' }}
											className="uk-button uk-align-right uk-button-default uk-modal-close"
											type="button"
										>
											Cancel
										</button>
										<button
											style={{ backgroundColor: 'blue' }}
											className="uk-button uk-align-right uk-button-primary uk-modal-close"
											type="button"
										>
											Save
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AdminLayout>
	);
}
export default Rule;
