import React, { useEffect, useState } from 'reactn';
import AdminLayout from '../../Layout/AdminLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Paginate from 'react-paginate';
// import Loader from "react-loader";
import ContentLoader, { BulletList, List } from "react-content-loader";
import swal from 'sweetalert2';
import edit from '../../assets/images/icon-edit.svg';
import { REACT_APP_API_URL } from '../../App';
import history from '../../browserHistory';
require('dotenv').config();

const TableRules = () => {
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${localStorage.getItem('tokenAdmin')}`
	};
	const [rules, setRules] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(4);
	const [page, setPage] = useState(0)

	useEffect(() => {
		const fetchItems = async () => {
			try {
				setIsLoading(true);
				let res = await axios.get(`${REACT_APP_API_URL}/core/v1/rules`, { headers });
				await setRules(res.data.rules);
				await setIsLoading(false);
			} catch (err) {
				await swal.fire({
					icon: 'error',
					title: 'Sesión caducada.',
					text: 'Tu sesión ha caducado, inicia sesión nuevamente!',
					allowOutsideClick: false,
					allowEscapeKey: false,
					backdrop: `
						rgb(91, 91, 91)
						center left
						no-repeat
  					`
				})
				await history.push('/')
				await history.go()
			}

		};
		fetchItems();
	}, []);


	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = rules.slice(indexOfFirstPost, indexOfLastPost);


	// Change page
	const paginates = pageNumber => setCurrentPage(pageNumber);

	function isoDateToShortDate(date) {
		const dateFormatted = new Date(date);
		return dateFormatted.getFullYear() + '-' + (dateFormatted.getMonth() + 1) + '-' + dateFormatted.getDate();
	}

	const handlePageClick = async (e) => {
		const selectedPage = e.selected;
		await setCurrentPage(selectedPage + 1)
		await setPage(selectedPage)
	};

	const disableRules = (data) => {
		swal
			.fire({
				title: '¿Estás seguro?',
				text: "No te preocupes, puedes revertir esto.",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Sí, deshabilítalo!'
			})
			.then((res) => {
				if (res.value) {
					data.status = 400000;
					axios
						.put(`${REACT_APP_API_URL}/core/v1/rules/`, { id: data.id, status: data.status }, { headers })
						.then((res) => {
							swal
								.fire('Deshabilitado!', `${data.key} ha sido deshabilitada.`, 'success')
								.then((notif) => window.location.reload());
						})
						.catch((err) => console.error(err));
				}
			});
	};

	const enableRules = (data) => {
		swal
			.fire({
				title: 'Are you sure?',
				text: "No te preocupes, puedes revertir esto!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Sí, Habilítala!'
			})
			.then((res) => {
				if (res.value) {
					data.status = 200000
					axios
						.put(`${REACT_APP_API_URL}/core/v1/rules/`, { id: data.id, status: data.status }, { headers })
						.then((res) => {
							swal
								.fire('Habilitado!', `${data.key} ha sido habilitada.`, 'success')
								.then((notif) => window.location.reload());
						})
						.catch((err) => console.error(err));
				}
			});
	};

	if (isLoading) {
		return (
			<AdminLayout>
				<div className="uk-width-5-6">

					<ContentLoader
						speed={2}
						width={1700}
						height={300}
						viewBox="-400 0 2000 1100"
						backgroundColor="#eaeced"
						foregroundColor="#ffffff"

					>
						<rect x="51" y="45" rx="3" ry="3" width="906" height="17" />
						<circle cx="879" cy="123" r="11" />
						<circle cx="914" cy="123" r="11" />
						<rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
						<rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
						<rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
						<rect x="55" y="155" rx="3" ry="3" width="897" height="2" />
						<circle cx="880" cy="184" r="11" />
						<circle cx="915" cy="184" r="11" />
						<rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
						<rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
						<rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
						<rect x="56" y="216" rx="3" ry="3" width="897" height="2" />
						<circle cx="881" cy="242" r="11" />
						<circle cx="916" cy="242" r="11" />
						<rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
						<rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
						<rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
						<rect x="57" y="274" rx="3" ry="3" width="897" height="2" />
						<circle cx="882" cy="303" r="11" />
						<circle cx="917" cy="303" r="11" />
						<rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
						<rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
						<rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
						<rect x="58" y="335" rx="3" ry="3" width="897" height="2" />
						<circle cx="881" cy="363" r="11" />
						<circle cx="916" cy="363" r="11" />
						<rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
						<rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
						<rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
						<rect x="57" y="395" rx="3" ry="3" width="897" height="2" />
						<circle cx="882" cy="424" r="11" />
						<circle cx="917" cy="424" r="11" />
						<rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
						<rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
						<rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
						<rect x="55" y="453" rx="3" ry="3" width="897" height="2" />
						<rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
						<rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
						<circle cx="882" cy="484" r="11" />
						<circle cx="917" cy="484" r="11" />
						<rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
						<rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
						<rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
						<rect x="55" y="513" rx="3" ry="3" width="897" height="2" />
						<rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
						<rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
						<rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
						<rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
						<rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
						<rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
					</ContentLoader>
				</div>
			</AdminLayout>
		);
	}
	if (error != null) {
		// console.log(error);
	}


	return (
		<AdminLayout>
			<div className="uk-width-5-6">
				<div className="table-wrapper">
					<div className="table-title">
						<div data-uk-grid>
							<div className="uk-width-1-2" />
							<div className="uk-width-1-2">
								<Link to="/admin/rules/create">
									<span className="uk-badge badgeSurvey" uk-icon="icon: plus">
										¡Nueva Regla!
									</span>
								</Link>
							</div>
						</div>
					</div>
					<div className="uk-overflow-auto">
						<table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Fecha de creación</th>
									<th>Creado por</th>
									<th>Estatus</th>
									<th>Acción</th>
								</tr>
							</thead>
							<tbody>
								{(currentPosts == 0) ? <div><p>No hay reglas para mostrar.</p></div> : currentPosts.map((data, index) => (
									<tr key={index}>
										{/* <Link className="uk-link-reset" style={{color: 'black'}} to={`/admin/rules/edit/${data.id}`}> */}
										<td onClick={() => document.location.href = `/admin/rules/edit/${data.id}`} className="pointer uk-table-link">
											{data.key}
										</td>
										{/* </Link> */}
										<td>{isoDateToShortDate(data.updated_at)}</td>
										<td>
											{data.creatorBy[0] === undefined ? (
												'Tuliox'
											) : (
												data.creatorBy[0].first_name + ' ' + data.creatorBy[0].last_name
											)}
										</td>
										<td>{`${data.status == 200000 ? 'Habilitado' : 'Deshabilitado'}`}</td>
										<td className="uk-text-nowrap">
											<span
												style={{ cursor: 'pointer', display: `${data.status == 400000 ? 'none' : ''}` }}
												title="Desactivar regla"
												onClick={() => disableRules(data)}
												className="uk-margin-small-right"
												uk-icon="icon: ban"
											/>
											<span
												style={{ cursor: 'pointer', display: `${data.status == 200000 ? 'none' : ''}` }}
												title="Activar regla"
												onClick={() => enableRules(data)}
												className="uk-margin-small-right"
												uk-icon="icon: check"
											/>
											<Link style={{ color: 'black' }} to={`/admin/rules/edit/${data.id}`}>
												<span title="Editar regla" className="uk-margin-small-right">
													<img style={{ width: '17%' }} src={edit} />
												</span>
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="clearfix" style={(currentPosts == 0) ? { display: 'none' } : { display: 'block' }}>
							<div className="hint-text">
								Mostrando <b>{currentPosts.length}</b> de <b>{rules.length}</b> reglas.
							</div>
							<Paginate
								previousLabel={(currentPage == 0) ? '' : 'Atrás'}
								nextLabel={'Siguiente'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={rules.length / postsPerPage}
								pageRangeDisplayed={postsPerPage}
								containerClassName={'pagination'}
								activeClassName={'active'}
								onPageChange={(e) => handlePageClick(e)}
							/>
						</div>
					</div>
				</div>
			</div>
		</AdminLayout>
	);
};

export default TableRules;
