import React, { useEffect, useState } from 'reactn';
import QueryBuilder from 'react-querybuilder';
import AdminLayout from '../../Layout/AdminLayout';
import axios from 'axios';
import { REACT_APP_API_URL } from '../../App';
import swal from "sweetalert2";
import history from '../../browserHistory';
import Loader from 'react-loader-spinner'

// import { Form } from 'react-formio/lib/components';
import { get, set, filter, union } from 'lodash';
import UIkit from 'uikit';

const operators = [
	{ name: 'equal', label: 'igual' },
	{ name: 'notEqual', label: 'diferente' },
	{ name: 'lessThan', label: 'menor que' },
	{ name: 'greaterThan', label: 'mayor que' },
	{ name: 'lessThanInclusive', label: 'menor o igual a' },
	{ name: 'greaterThanInclusive', label: 'mayor o igual a' }
];

const headers = {
	'Content-Type': 'application/json',
	Authorization: `Bearer ${localStorage.getItem('tokenAdmin')}`
};


const EditRules = () => {

	const [RulesById, setRuleById] = useState(undefined)
	const [PropertiesRules, setPropertiesRules] = useState(undefined)
	const [isLoading, setIsLoading] = useState(true);
	const [Forms, setForms] = useState([]);
	const [surveyList, setSurveyList] = useState([]);
	const [CheckboxTable, setCheckboxTable] = useState(false);
	const [ValueKey, setValueKey] = useState('');
	const [Fields, setFields] = useState([]);
	const [query, setQuery] = useState({})

	useEffect(() => {

		let fetchRulesById = async () => {
			let RuleById = await axios.get(`${REACT_APP_API_URL}/core/v1/rules/${idRules}`, { headers })
			setRuleById(RuleById.data)
			setValueKey(RuleById.data.key)
			setQuery(RuleById.data.properties)
		}


		fetchRulesById()

		const fetchFacts = async () => {
			try {
				setIsLoading(true);
				let facts = await axios.post(`${REACT_APP_API_URL}/core/v1/authentication/facts`, {}, { headers });
				await setFields(facts.data);
				await setIsLoading(false);
			} catch (err) {
				// console.log(err)
			}
		};
		fetchFacts();

			//definitions
			const fetchDefinitions = async () => {
				try {
					setIsLoading(true);
					let facts = await axios.get(`${REACT_APP_API_URL}/core/v1/forms/definitions`, { headers });
					await setForms(facts.data[0].metas);
					await setIsLoading(false);
				} catch (err) {
					await swal.fire({
						icon: 'error',
						title: 'Sesión caducada.',
						text: 'Tu sesión ha caducado, inicia sesión nuevamente!',
						allowOutsideClick: false,
						allowEscapeKey: false,
						backdrop: `
								rgb(91, 91, 91)
								center left
								no-repeat
							  `
					});
					 history.push('/');
					 history.go();
				}
			};
			fetchDefinitions();

	}, [])


	useEffect(()=> {
	}, [RulesById])

	let idRules = window.location.pathname.replace('/admin/rules/edit/', '')

	const changeValue = (e) => {
		setValueKey(e.target.value)
	}

	const hanbleUpdateRule = () => {

		let newProperties = RulesById.properties;
		newProperties.conditions = PropertiesRules

		try {

			swal
				.fire({
					title: '¿Ya seleccionaste las encuestas que estarán asociadas a esta regla?',
					text: 'Esta regla única y exclusivamente funcionarán a las encuestas que selecciones.',
					allowOutsideClick: false,
					allowEscapeKey: false,
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí!',
					cancelButtonText: 'No!',
					icon: 'warning'
				}).then((resp) => {
						if (resp.value) {
			swal
				.fire({
					title: '¿Estás seguro?',
					text: 'Una vez guardado, esto afecta todas las encuestas compatibles a esta regla.',
					allowOutsideClick: false,
					allowEscapeKey: false,
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Save!',
					icon: 'warning'
				}).then((resp) => {

					if (resp.value) {
						axios.put(`${REACT_APP_API_URL}/core/v1/rules/${idRules}`, { key: ValueKey, properties: newProperties }, { headers }).then((resp) => {

							if (resp.status == 200) {
								swal.fire({
									position: 'center',
									icon: 'success',
									title: 'Tus configuraciones han sido guardadas.',
									showConfirmButton: true,
									allowOutsideClick: false,
									allowEscapeKey: false
								}).then((ok) => {
									if (ok.isConfirmed) {
										history.push('/admin/rules')
										history.go()
									}
								});;
							}
						})
					} 

				})
						} else {
							UIkit.modal('#modal-sections').show();
						}
				})

			


		} catch (error) {
			// console.log(error)
		}
	}
	const HandleSelectedSurvey = (data) => {

		
		let ruleByIdSurvey = filter(get(RulesById, 'properties.event.params.surveys'), (survey) => (survey != data.target.value) )

		let newRuleById = set(Object.assign(RulesById, []), 'properties.event.params.surveys', (data.target.checked) ? union(ruleByIdSurvey, [data.target.value]) : ruleByIdSurvey)
		
		setRuleById(newRuleById)
	};

	const hanbleCancelRule = () => {
		swal
			.fire({
				title: 'Cancelar cambios',
				text: 'Si cancelas, tendrás que hacer los cambios nuevamente.',
				allowOutsideClick: false,
				allowEscapeKey: false,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Deseo quedarme',
				icon: 'warning'
			}).then((resp) => {
				if (!resp.value) {
					history.push('/admin/rules')
					history.go()
				}
			})
	}
		
	const handleCheckedGivenId = (e) => {
		let isChecked = e.target;
	}


	return (
		<>
		<AdminLayout>
			{(isLoading) ? (<div className="uk-position-center">
        <Loader
      type="Puff"
      color="#00BFFF"
      height={100}
      width={100}
      timeout={3000} //3 secs
  
   />
      </div>): (
			<div className="uk-flex uk-flex-column uk-width-5-6">
				<div className="rule-menu uk-width-1-1">
					<button
						style={{ backgroundColor: 'blue' }}
						onClick={hanbleUpdateRule}
						className="uk-button uk-button-primary uk-margin-top uk-margin-left uk-margin-bottom uk-text-bold"
					>
						Actualizar
                    </button>
					<button
						onClick={hanbleCancelRule}
						className="uk-button uk-button-danger uk-margin-top uk-margin-left uk-margin-bottom uk-text-bold"
					>
						Cancelar
                    </button>
					<button
                        style={{ backgroundColor: 'blue' }}
                        className="uk-button uk-align-right uk-button-primary uk-margin-top uk-margin-right uk-margin-bottom uk-text-bold"
                        uk-toggle="target: #modal-sections"
                        type="button"
                    >
                        Encuestas
                    </button>
					{(RulesById) ? <div>
						<label className="labelKeyRules">
							Key
                            <input value={ValueKey} onChange={(e) => changeValue(e)} maxLength={40} type="text" id="input-key" className="inputKeyRules" />
						</label>
					</div> : <div></div>}

				</div>
				<div uk-grid="true">
					<div className="uk-margin-top uk-width-2-3">
						{(RulesById) ? <QueryBuilder
							className="uk-margin-remove"
							operators={operators}
							fields={Fields}
							query={RulesById.properties.conditions}
							onQueryChange={(q) => {
								setPropertiesRules(q)
							}}
						/> : <div></div>}
					</div>

					<div className="uk-margin-top uk-width-1-3">
						<div className="uk-overflow-auto">
							<div id="modal-sections" uk-modal="true" bg-close="false">
								<div className="uk-modal-dialog">
									{/* <button className="uk-modal-close-default" type="button" uk-close>Cerrar</button> */}
									<div style={{ background: 'none' }} className="uk-modal-header">
										<h2
											style={{ fontSize: '32px' }}
											className="uk-modal-title uk-text-center uk-text-normal"
										>
											Selecciona al menos una encuesta.
										</h2>
									</div>
									<div className="uk-modal-body">
										<table
											id="table-survey"
											className="uk-table uk-table-hover uk-table-middle uk-table-divider"
										>
											<tbody>
												{Forms.map((form, i) => {
													return (
														<tr key={i + '-'}>
															<td>
																<input
																	style={{ display: 'inline-block' }}
																	className="uk-checkbox form-list-checked"
																	type="checkbox"
																	value={form.id}
																	name={form.key}
																	defaultChecked={get(RulesById, 'properties.event.params.surveys').includes(form.id)}
																	onChange={(element) => {
																		HandleSelectedSurvey(element);
																	}}
																/>
															</td>
														<td>{form.key}</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
									<hr />
									{/* <pre>{get(RulesById, 'properties.event.params.surveys') + ', ' }</pre> */}
									<div className="uk-modal-footer uk-text-right">
										<button
											style={{ backgroundColor: 'red' }}
											className="uk-button uk-align-right uk-button-default uk-modal-close"
											type="button"
										>
											Cancel
										</button>
										<button
											style={{ backgroundColor: 'blue' }}
											className="uk-button uk-align-right uk-button-primary uk-modal-close"
											type="button"
										>
											Save
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<br />
				</div>
			</div>
			)}
		</AdminLayout>
		
		</>
	);
}

export default EditRules;