import React, { useEffect, useState } from 'react';
import { HorizontalBar, Doughnut, Line, Bar } from 'react-chartjs-2';
import AdminLayout from '../../Layout/AdminLayout';
import { REACT_APP_API_URL } from '../../App';
import axios from 'axios';

require('dotenv').config();

export const Home = (props) => {

	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [items, setItems] = useState([]);

	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${localStorage.getItem('tokenAdmin')}`
	};
	let keySurvey = window.location.pathname.split('/', 4);
	keySurvey = keySurvey[3];

	let dataHorizontalBar = {
		labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
		datasets: [
			{
				label: 'Pools',
				backgroundColor: 'rgba(255,99,132,0.2)',
				borderColor: 'rgba(255,99,132,1)',
				borderWidth: 1,
				hoverBackgroundColor: 'rgba(255,99,132,0.4)',
				hoverBorderColor: 'rgba(255,99,132,1)',
				data: [65, 59, 80, 81, 56, 55, 40]
			}
		]
	};

	const dataDoughnutByAge = {
		labels: [
			'18-25',
			'26-35',
			'36-45',
			'46-55',
			'Más de 56'
		],
		datasets: [{
			data: [300, 225.5, 50.9, 57, 89],
			backgroundColor: [
				'#E5AB69',
				'#2F6B65',
				'#325682',
				'#FFC972',
				'#FF7A9D',
			],
			hoverBackgroundColor: [
				'#E69942',
				'#3A9188',
				'#003832',
				'#FCA311',
				'#FF0043'
			]
		}]
	};

	const dataDoughnutByGenders = {
		labels: [
			'Mujeres',
			'Hombres',
			'Otros'
		],
		datasets: [{
			data: [300, 225, 50],
			backgroundColor: [
				'#ffcadb',
				'#87CEFA',
				'#DCDCDC'
			],
			hoverBackgroundColor: [
				'#FF69B4',
				'#00BFFF',
				'#696969'
			]
		}]
	};

	// console.log(items.map((value, i) => {return value.label}))
	const dataLine = {
        labels: ['Milex', 'Pepsi', 'DPH', 'PHD', 'Microsoft', 'OMD', 'Pages'],
        datasets: [
            {
                label: 'Total de formularios respondidos',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#01bfff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(255,99,132,0.4)',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 10,
                pointHitRadius: 10,
                data: [65, 59, 80, 81, 56, 55, 50]
            }
        ]
    };

	const dataBar = {
		data: {
			datasets: [{
				label: 'Bar Dataset',
				data: [10, 20, 30, 40],
				order: 1
			}, {
				label: 'Line Dataset',
				data: [10, 10, 10, 10],
				type: 'line',
				order: 2
			}],
			labels: ['Enero', 'Febrero', 'Marzo', 'Abril']
		}
	};


	useEffect(() => {

		const fetchData = async () => {
			try {
				setIsLoaded(true);
				const response = await axios.post(`${REACT_APP_API_URL}/core/v1/charts`, { keySurvey: keySurvey }, { headers });
				// setItems(response.data);
				console.log(response.data);
			} catch (error) {
				console.log("error", error);
				setIsLoaded(true);
				setError(error);
			}
		};

		fetchData();
	}, []);

	return (
		<AdminLayout>

			{/* <div className="page-content-wrapper uk-margin-top">
					<h3>Wellcome Admin</h3>
					<p className="uk-h3 uk-margin-remove-bottom">What would you like to do today?</p>
				</div> */}
			{/* {console.log('items', items)} */}

			<div className="uk-grid uk-width-5-6">

				<div className="uk-width-1-2@m">
					<h3 className="uk-text-center">Rango por edad</h3>
					<Doughnut data={dataDoughnutByAge} />
					<hr className="uk-grid-divider" />
				</div>

				<div className="uk-width-1-2@m">
					<h3 className="uk-text-center">Géneros</h3>
					<Doughnut data={dataDoughnutByGenders} />
					<hr className="uk-grid-divider" />
				</div>

				<div className="uk-width-1-2@m">
					<h3 className="uk-text-center">Formularios por mes</h3>
					<Line data={dataLine} />
					<hr className="uk-grid-divider" />
				</div>

				<div className="uk-width-1-2@m">
					<h3 className="uk-text-center">¿Usted tiene vehículo?</h3>
					<Bar data={dataBar.data} />
					<hr className="uk-grid-divider" />
				</div>
			</div>


		</AdminLayout>
	);
};

export default Home;
