import React from "react";
import "./App.reducers";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Admin from "./containers/Administrator/index";
import Rules from "./containers/Rules/Rules";
import EditRules from "./containers/Rules/EditRules";
import FormHubUpdate from "./containers/FormHub/Update";
import LoginAdmin from "./containers/LoginAdmin/Login";
import FormHubNew from "./containers/FormHub/New";
import TableFormBuilder from "./components/TableFormBuilder";
import Whitelist from "./components/WhiteList/WhiteList";
import "./css/style.css";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import TableRules from "./containers/Rules/TableRules";
import history from "./browserHistory";
import UserAdminProfile from "./components/UserAdminProfile";
import Reports from "./components/Reports/Reports";
import NotFound from "./components/NotFound";

UIkit.use(Icons);

const detectHost = () => {
  let currentHost = window.location.hostname;
  currentHost = currentHost.split(".");
  let port =
    window.location.port != ""
      ? ":" + window.location.port
      : window.location.port;
  if (currentHost[0].toLocaleLowerCase() === "admin") {
    currentHost[0] = "api";
  } else {
    if (currentHost[0].toLocaleLowerCase() === "localhost") {
    } else {
      currentHost = currentHost.reverse();
      currentHost.push("api");
      currentHost = currentHost.reverse();
    }
  }
  currentHost = currentHost.join(".");
  return "https://" + currentHost + port;
};

export const REACT_APP_API_URL = detectHost();

const CheckAuth = () => {
  let url = history.location.pathname.includes("/admin");
  let tokenAdmin = localStorage.getItem("tokenAdmin");

  let results = {
    pathname: "/",
    pass: false,
  };

  if (url) {
    if (tokenAdmin != null && tokenAdmin != undefined) {
      results.pass = true;
    } else {
      results.pathname = "/";
    }
  } else if (!tokenAdmin) {
    results.pathname = "/";
  } else {
    results.pass = true;
    results.pathname = "/admin";
  }

  return results;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  let checkauth = CheckAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        checkauth.pass ? (
          <Component {...props} />
        ) : (
          <Redirect push={true} to={{ pathname: checkauth.pathname }} />
        )
      }
    />
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginAdmin} />
        <AuthRoute exact path="/admin" component={TableFormBuilder} />
        <AuthRoute exact path="/admin/rules" component={TableRules} />
        <AuthRoute path="/admin/rules/create" component={Rules} />
        <AuthRoute path="/admin/rules/edit" component={EditRules} />
        <AuthRoute path="/admin/account" component={UserAdminProfile} />
        <AuthRoute path="/admin/charts/:key" component={Admin} />
        <AuthRoute path="/admin/formhub/update" component={FormHubUpdate} />
        <AuthRoute path="/admin/formhub/new" component={FormHubNew} />
        <AuthRoute path="/admin/whitelist" component={Whitelist} />
        <AuthRoute path="/admin/reports" component={Reports} />
        <AuthRoute path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
