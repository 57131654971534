import React from 'reactn';
import { Link } from 'react-router-dom';

const SideBar = ({ keyForm, components, children }) => {
let checkRoute = () => {
// let n;
// 	switch (window.location.pathname) {
	
// 			case '/admin':
// 			 n = '24%';
// 			break;
// 			case '/admin/charts':
// 			 n = '32%';
// 			break;
// 			case '/admin/rules/create':
// 			 n = '20%';
// 			break;
// 			case '/admin/rules':
// 			n = '25%';
// 			break;
// 			case '/admin/whitelist':
// 			 n = '17%';
// 			break;
// 			case '/admin/account':
// 			 n = '17%';
// 			break; 
// 			case `/admin/rules/edit/${window.location.pathname.replace('/admin/rules/edit/', '')}`:
// 			 n = '20%';
// 			break; 
// 		default:
// 			n =	'37%'
// 			break;
// 	}
// 	return n;

// tyle={{width: checkRoute()}} 
}
	return (
		<main className="uk-flex uk-height-viewport">
			{/* SIDEBAR NAVIGATION */}
			<div className="uk-width-1-6 uk-padding-small nav-sidebar">
				<nav>
					<div className="uk-width-1-2@s uk-width-2-5@m">
						<ul className="uk-nav uk-nav-default">
							{/* <li class="uk-nav-header">Header</li> */}
                            <h3 className="uk-text-uppercase nav-h2-sidebar uk-text-nowrap uk-margin-top">Market Research</h3>
                            <li className="uk-text-nowrap"><Link rel="preload" to="/admin"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: file-text"></span>Encuestas</Link></li>
                            <li className="uk-text-nowrap"><Link rel="preload" to="/admin/rules"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: nut"></span>Reglas</Link></li>
							{/* <li className="uk-text-nowrap"><Link rel="preload" to="/whitelist"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: list"></span>Whitelist</Link></li>
							<li className="uk-text-nowrap"><Link rel="preload" to="#"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: settings"></span>Other Option</Link></li>
							<li className="uk-text-nowrap"><Link rel="preload" to="#"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: lock"></span>Other Option</Link></li>
							<li className="uk-text-nowrap"><Link rel="preload" to="#"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: ban"></span>Other Option</Link></li> */}
						</ul>
					</div>
					{/* <br/> */}
                    <div className="uk-width-1-2@s uk-width-2-5@m">
						<ul className="uk-nav uk-nav-default">
							{/* <li class="uk-nav-header">Header</li> */}
                            <h3 className="uk-text-uppercase nav-h2-sidebar uk-margin-top">Reports</h3>
                            <li className="uk-text-nowrap"><Link rel="preload" to="/admin/reports"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: folder"></span>Reportes</Link></li>
						</ul>
					</div>
					{/* <br/> */}

                    <div className="uk-width-1-2@s uk-width-2-5@m">
						<ul className="uk-nav uk-nav-default">
							{/* <li class="uk-nav-header">Header</li> */}
                            <h3 className="uk-text-uppercase nav-h2-sidebar uk-margin-top">Users</h3>
                            <li className="uk-text-nowrap" ><Link rel="preload" to="#"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: user"></span>Option 1</Link></li>
                            {/* <li><Link rel="preload" to="#"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: users"></span>Option 2</Link></li> */}
						</ul>
					</div>
					{/* <br/> */}
                    <div className="uk-width-1-2@s uk-width-2-5@m">
						<ul className="uk-nav uk-nav-default">
							{/* <li class="uk-nav-header">Header</li> */}
                            {/* <h3 className="uk-text-uppercase nav-h2-sidebar uk-text-nowrap">enter title here</h3> */}
                            {/* <li><Link rel="preload" to="#"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: tag"></span>Option 1</Link></li> */}
                            {/* <li><Link rel="preload" to="#"><span className="uk-margin-small-right uk-margin-left" uk-icon="icon: cart"></span>Option 2</Link></li> */}
						</ul>
					</div>
				</nav>
			</div>
			{/* CONTENT PART */}
			{children}
		</main>
	);
};

export default SideBar;
